import { FC, useMemo } from "react";
import isString from "lodash/isString";

import styles from "./AssistantVolumeSharePieChartTool.module.scss";
import { formatToLocaleNumber } from "src/utils";
import { PieChartWithLabels } from "src/components";
import { renderPieChartLabel } from "src/features/Widgets/Components/SearchShare/utils";

// Inner imports
import { VolumeSharePieChartTool } from "./types";
import { formatVolumeSharePieChartData } from "./utils";

type Props = Record<string, unknown>;

export const AssistantVolumeSharePieChartTool: FC<Props> = ({ result }) => {
  const { dateTo, dateFrom, data } = useMemo<VolumeSharePieChartTool>(() => {
    if (!isString(result)) return { dateFrom: "", dateTo: "", data: [] };

    return JSON.parse(result) || { dateFrom: "", dateTo: "", data: [] };
  }, [result]);

  const formattedTrackers = useMemo<Pick<Tracker.Data, "id" | "name">[]>(
    () =>
      data.map(({ topicId, topicName }) => ({ id: topicId, name: topicName })),
    [data],
  );

  const formattedPieChart = useMemo<Widgets.PieChart.Item>(
    () => formatVolumeSharePieChartData(data, dateFrom, dateTo),
    [data, dateFrom, dateTo],
  );

  if (!data.length) return null;

  return (
    <div className={styles.wrapper}>
      <PieChartWithLabels
        data={formattedPieChart}
        trackers={formattedTrackers}
        renderLabel={renderPieChartLabel}
        valueFormatter={formatToLocaleNumber}
      />
    </div>
  );
};
