import {
  FC,
  useRef,
  useMemo,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import { LabelRenderProps } from "react-minimal-pie-chart/types/Label";

import styles from "./PieChartWithLabels.module.scss";
import { TrackerChartIcon } from "src/features";
import { GRAY, CHART_COLORS } from "src/constants/colors";
import { formatToMonthDayFullYearDate, roundNumber } from "src/utils";

// Inner imports
import { PieChartWithLabelsProps, PreparedPieChartDataItem } from "./types";
import {
  calculatePieChartTotalValue,
  calculatePieChartTotalAdditionalValue,
} from "./utils";
import {
  PIE_CHART_STYLE_PROPS,
  PIE_CHART_MIN_PERCENTAGE,
  PIE_CHART_SMALL_PERCENTAGE,
  PIE_CHART_SMALL_LABEL_LENGTH,
  PIE_CHART_TOOLTIP_EXTRA_SPACE,
  PIE_CHART_TOOLTIP_PARENT_OFFSET_TOP,
} from "./constants";

export const PieChartWithLabels: FC<PieChartWithLabelsProps> = ({
  data,
  trackers,
  renderValue,
  renderLabel,
  allTrackerIds,
  className = "",
  valueFormatter,
  hasTotal = false,
  hasLabels = true,
  renderTooltipLabel,
  chartClassName = "",
  dateFormatter = formatToMonthDayFullYearDate,
  calculateTotalValue = calculatePieChartTotalValue,
  calculateTotalAdditionalValue = calculatePieChartTotalAdditionalValue,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { values, startDate, endDate } = useMemo<Widgets.PieChart.Item>(
    () => data,
    [data],
  );

  const [selectedTrackerIndex, setSelectedTrackerIndex] = useState<
    number | null
  >(null);

  const [coordinates, setCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0,
  });

  const formattedData = useMemo<PreparedPieChartDataItem[]>(() => {
    const formattedData: PreparedPieChartDataItem[] = [];

    if (allTrackerIds?.length) {
      for (const [index, id] of allTrackerIds.entries()) {
        const tracker = trackers.find((tracker) => tracker.id === id);

        if (!tracker) continue;

        const data = values.find((dataItem) => dataItem.trackerId === id);

        if (!data) continue;

        const trackerColor = CHART_COLORS[index] || GRAY;

        formattedData.push({
          id: tracker.id,
          label: tracker.name,
          color: trackerColor,
          value: Number(data.value.toFixed(2)),
          additionalValue: data.additionalValue,
          formattedValue: Number(data.value.toFixed()),
        });
      }
    } else {
      for (const [index, trackerOption] of trackers.entries()) {
        const { id: labelId, name: label } = trackerOption;

        const data = values.find((dataItem) => dataItem.trackerId === labelId);

        if (!data) continue;

        const trackerColor = CHART_COLORS[index] || GRAY;

        formattedData.push({
          label,
          id: labelId,
          color: trackerColor,
          additionalValue: data.additionalValue,
          formattedValue: Number(data.value.toFixed()),
          value: Number(data.value.toFixed(2)),
        });
      }
    }

    return formattedData.sort((a, b) => b.value - a.value);
  }, [allTrackerIds, trackers, values]);

  const selectedTrackerData = useMemo<
    PreparedPieChartDataItem | undefined
  >(() => {
    if (selectedTrackerIndex === null) return;

    return formattedData[selectedTrackerIndex];
  }, [formattedData, selectedTrackerIndex]);

  const hasData = useMemo<boolean>(
    () => Boolean(formattedData.length),
    [formattedData],
  );

  const [totalValue, totalAdditionalValue] = useMemo<[number, number]>(() => {
    if (!hasTotal) return [0, 0];

    const [totalValue, totalAdditionalValue] = [
      calculateTotalValue(data),
      calculateTotalAdditionalValue(data),
    ];

    return [roundNumber(totalValue), totalAdditionalValue];
  }, [data, hasTotal, calculateTotalValue, calculateTotalAdditionalValue]);

  const pieChartLabelFormatter = (
    props: LabelRenderProps<PreparedPieChartDataItem>,
  ): string => {
    const { formattedValue, percentage } = props.dataEntry;

    if (percentage <= PIE_CHART_MIN_PERCENTAGE) return "";

    const value = valueFormatter?.(formattedValue) || String(formattedValue);

    if (
      percentage <= PIE_CHART_SMALL_PERCENTAGE &&
      value.length >= PIE_CHART_SMALL_LABEL_LENGTH
    )
      return "";

    return value;
  };

  const onPieChartMouseOver = (
    event: ReactMouseEvent<Element, MouseEvent>,
    dataIndex: number,
  ): void => {
    const { offsetX: elementOffsetX, offsetY: elementOffsetY } =
      event.nativeEvent;

    const isTooltipOverflowParentTop =
      elementOffsetY >= PIE_CHART_TOOLTIP_PARENT_OFFSET_TOP;

    const [tooltipX, tooltipY] = [
      elementOffsetX,
      isTooltipOverflowParentTop
        ? elementOffsetY - PIE_CHART_TOOLTIP_EXTRA_SPACE
        : PIE_CHART_TOOLTIP_PARENT_OFFSET_TOP,
    ];

    setCoordinates({ x: tooltipX, y: tooltipY });

    setSelectedTrackerIndex(dataIndex);
  };

  const onPieChartMouseOut = (): void => setSelectedTrackerIndex(null);

  if (!hasData) return null;

  return (
    <div className={cx(styles.wrapper, className)} ref={wrapperRef}>
      <div className={cx(styles.chartWrapper, chartClassName)}>
        <PieChart
          className={styles.chart}
          label={pieChartLabelFormatter}
          onMouseOut={onPieChartMouseOut}
          onMouseOver={onPieChartMouseOver}
          data={formattedData.filter(({ value }) => Boolean(value))}
          {...PIE_CHART_STYLE_PROPS}
        />
        {selectedTrackerData && (
          <div
            className={styles.tooltip}
            style={{ top: coordinates.y, left: coordinates.x }}
          >
            <span className={styles.tooltipTitle}>
              {t("chart.pie.tooltip.label", {
                endDate: dateFormatter(endDate),
                startDate: dateFormatter(startDate),
              })}
            </span>
            <div className={styles.tooltipItem}>
              <div className={styles.tooltipItemTitle}>
                {Boolean(selectedTrackerData?.color) && (
                  <TrackerChartIcon color={selectedTrackerData?.color} />
                )}
                {renderTooltipLabel?.(selectedTrackerData) || (
                  <span className={styles.tooltipItemLabel}>
                    {selectedTrackerData?.label}
                  </span>
                )}
              </div>
              <span className={styles.tooltipItemValue}>
                {t("chart.pie.tooltip.value", {
                  value:
                    valueFormatter?.(selectedTrackerData?.value || 0) ||
                    selectedTrackerData?.value,
                })}
              </span>
            </div>
          </div>
        )}
        {hasLabels && (
          <div className={styles.labelsWrapper}>
            <div className={styles.labels}>
              {formattedData.map((data) => (
                <div key={data.id} className={styles.label} title={data.label}>
                  <div className={styles.labelNameWrapper}>
                    <TrackerChartIcon color={data.color} />
                    {renderLabel?.(data) || (
                      <span className={styles.labelName}>{data.label}</span>
                    )}
                  </div>
                  <div className={styles.labelValue}>
                    {renderValue?.(data.value, data.additionalValue) ||
                      valueFormatter?.(data.value) ||
                      data.value}
                  </div>
                </div>
              ))}
            </div>
            {hasTotal && (
              <div className={styles.label}>
                <div className={styles.labelTotalWrapper}>
                  {t("chart.pie.data.label.total")}
                </div>
                <div className={styles.labelValue}>
                  {renderValue?.(totalValue, totalAdditionalValue) ||
                    valueFormatter?.(totalValue) ||
                    totalValue}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
