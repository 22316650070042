import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./TrackersSection.module.scss";
import { useTrackerBlocker } from "src/hooks";
import { SelectOption } from "src/components/selectors/Select/types";
import {
  TrackerStatusIcon,
  TrackerKeywordsDataSourcesIcon,
} from "src/features";
import {
  ROUTES,
  TRACKER_SORT_OPTIONS,
  SEARCH_KEYWORDS_DATA_SOURCE_MAP,
} from "src/constants";
import {
  sortTrackers,
  filterTrackers,
  getIconByName,
  getUserFullName,
  showToastNotification,
  isKeywordsDataSourceTypeGuard,
} from "src/utils";
import {
  Plus,
  User,
  Loader,
  MinusCircleFill,
  PlusCircleOutline,
} from "src/assets/icons";
import {
  Button,
  Select,
  Tooltip,
  Translation,
  InputWithIcon,
} from "src/components";
import {
  selectUser,
  selectCompanyMembers,
  selectCompanyTrackers,
  selectDashboardsLimit,
  selectWidgetSyncsByTrackersCollectionId,
  selectSubscriptionPlanKeywordsDataSources,
} from "src/store/selectors";

type Props = {
  usedTrackers: Tracker.Data[];
  isUsedTrackersChanged: boolean;
  onUpdateClickHandler: () => void;
  onCreateNewCollectionClickHandler: () => void;
  trackersCollectionId: TrackersCollection.Data["id"];
  addUsedTracker: (trackerId: Tracker.Data["id"]) => void;
  removeUsedTracker: (trackerId: Tracker.Data["id"]) => void;
};

export const TrackersSection: FC<Props> = ({
  usedTrackers,
  addUsedTracker,
  removeUsedTracker,
  trackersCollectionId,
  onUpdateClickHandler,
  isUsedTrackersChanged,
  onCreateNewCollectionClickHandler,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const trackers = useSelector(selectCompanyTrackers);

  const { dashboardsLimit, isDashboardsLimitExceeded } = useSelector(
    selectDashboardsLimit,
  );

  const { isTrackerBlocked, showTrackerBlockerNotification } =
    useTrackerBlocker();

  const user = useSelector(selectUser);

  const widgetSyncs = useSelector((state: Store.RootState) =>
    selectWidgetSyncsByTrackersCollectionId(state, trackersCollectionId),
  );

  const companyMembers = useSelector(selectCompanyMembers);

  const subscriptionPlanKeywordsDataSources = useSelector(
    selectSubscriptionPlanKeywordsDataSources,
  );

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [sortType, setSortType] = useState<Tracker.ListSort>("lastViewed");

  const [filterMemberId, setFilterMemberId] =
    useState<Company.Member["id"]>("all");

  const [filterKeywordsDataSource, setFilterKeywordsDataSource] = useState<
    Search.KeywordsDataSource | "all"
  >("all");

  const keywordsDataSourceOptions = useMemo<SelectOption[]>(() => {
    const options = new Set<SelectOption>();

    options.add({
      value: "all",
      label: t("page.dashboard.form.data_source.all"),
    });

    for (const dataSource of subscriptionPlanKeywordsDataSources) {
      if (!isKeywordsDataSourceTypeGuard(dataSource)) continue;

      options.add({
        value: dataSource,
        label: t(SEARCH_KEYWORDS_DATA_SOURCE_MAP[dataSource].labelKey),
        renderIcon: () =>
          getIconByName(SEARCH_KEYWORDS_DATA_SOURCE_MAP[dataSource].icon),
      });
    }

    return Array.from(options);
  }, [subscriptionPlanKeywordsDataSources, t]);

  const companyMemberOptions = useMemo<SelectOption[]>(() => {
    const options = new Set<SelectOption>();

    options.add({
      value: "all",
      label: t("page.dashboard.form.author_id.all"),
    });

    for (const memberId in companyMembers) {
      const member = companyMembers[memberId];

      if (!member) continue;

      const memberName =
        memberId === user.id
          ? t("page.dashboard.form.author_id.current_user", {
              name: getUserFullName(member),
            })
          : getUserFullName(member);

      options.add({ value: memberId, label: memberName });
    }

    return Array.from(options);
  }, [companyMembers, t, user.id]);

  const filteredTrackers = useMemo<Tracker.Data[]>(
    () =>
      filterTrackers({
        trackers,
        query: searchQuery,
        authorId: filterMemberId,
        keywordsDataSource: filterKeywordsDataSource,
      }),
    [trackers, searchQuery, filterMemberId, filterKeywordsDataSource],
  );

  const sortedTrackers = useMemo<Tracker.Data[]>(
    () =>
      sortTrackers({
        type: sortType,
        trackers: filteredTrackers,
        lastViewedTrackerIds: user.lastViewedTrackerIds,
      }),
    [sortType, filteredTrackers, user.lastViewedTrackerIds],
  );

  const hasWidgetSync = useMemo<boolean>(
    () => widgetSyncs.length > 0,
    [widgetSyncs.length],
  );

  const isUpdateButtonDisabled = useMemo<boolean>(
    () => !isUsedTrackersChanged || usedTrackers.length <= 0 || hasWidgetSync,
    [hasWidgetSync, isUsedTrackersChanged, usedTrackers.length],
  );

  const isCreateButtonDisabled = useMemo<boolean>(
    () => !isUsedTrackersChanged || usedTrackers.length <= 0,
    [isUsedTrackersChanged, usedTrackers.length],
  );

  const updateButtonTooltip = useMemo<string | JSX.Element>(
    () =>
      hasWidgetSync
        ? t("page.dashboard.tooltip.sync_calculation")
        : t("page.dashboard.tooltip.update_dashboard"),
    [hasWidgetSync, t],
  );

  const getAuthorFullName = useCallback(
    (value: User.Data["id"]): string => {
      const member = companyMembers[value];

      if (!member) return "";

      if (user.id === value)
        return t("page.dashboard.label.current_user", {
          name: getUserFullName(member),
        });

      return getUserFullName(member);
    },
    [companyMembers, t, user.id],
  );

  const onUpdateClicked = () => {
    if (isUpdateButtonDisabled) return;

    onUpdateClickHandler();
  };

  const onCreateNewCollectionClicked = (): void => {
    if (isCreateButtonDisabled) return;

    if (isDashboardsLimitExceeded)
      return showToastNotification({
        id: "dashboard-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="dashboard.status.warning.dashboard_limit"
            values={{ count: dashboardsLimit }}
          />
        ),
      });

    onCreateNewCollectionClickHandler();
  };

  const onTrackerClick = (trackerId: Tracker.Data["id"]) =>
    checkIfTrackerSelected(trackerId)
      ? removeUsedTracker(trackerId)
      : addUsedTracker(trackerId);

  const onSortTypeChange = (value: Tracker.ListSort) => setSortType(value);

  const onCreateTrackerClick = () => {
    if (isTrackerBlocked) return showTrackerBlockerNotification();

    const usedTrackerIdsString = usedTrackers.map(({ id }) => id).join(",");

    const queryParams = new URLSearchParams({
      topics: usedTrackerIdsString,
      dashboard: trackersCollectionId,
    }).toString();

    history.push(`${ROUTES.trackerSelection}?${queryParams}`);
  };

  const onChangeFilterMemberId = (value: Company.Member["id"]): void =>
    setFilterMemberId(value);

  const onChangeFilterKeywordsDataSource = (value: string): void => {
    if (!isKeywordsDataSourceTypeGuard(value))
      return setFilterKeywordsDataSource("all");

    setFilterKeywordsDataSource(value);
  };

  function checkIfTrackerSelected(trackerId: Tracker.Data["id"]): boolean {
    return usedTrackers.some(({ id }) => id === trackerId);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.formGroup}>
          <InputWithIcon
            icon="Search"
            hasClearButton
            value={searchQuery}
            className={styles.filterInput}
            changeHandler={setSearchQuery}
            placeholder={t("page.dashboard.form.placeholder.search_query")}
          />
        </div>
        <div className={styles.formGroup}>
          <Select
            icon="User"
            hasFilter={false}
            value={filterMemberId}
            options={companyMemberOptions}
            changeHandler={onChangeFilterMemberId}
          />
          <Select
            icon="Filter"
            hasFilter={false}
            options={keywordsDataSourceOptions}
            value={filterKeywordsDataSource || ""}
            changeHandler={onChangeFilterKeywordsDataSource}
          />
        </div>
        <div className={styles.formGroup}>
          <Select
            icon="Sort"
            value={sortType}
            options={TRACKER_SORT_OPTIONS}
            inputClassName={styles.sortInput}
            selectClassName={styles.sortSelect}
            changeHandler={(value: string) =>
              onSortTypeChange(value as Tracker.ListSort)
            }
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <span>{t("page.dashboard.label.trackers")}</span>
            <div className={styles.sectionActions}>
              <Tooltip content={t("page.dashboard.tooltip.create_tracker")}>
                <Button buttonSize="small" onClick={onCreateTrackerClick}>
                  <Plus />
                  <span>{t("page.dashboard.button.create_tracker")}</span>
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={styles.sectionItems}>
            {sortedTrackers.length ? (
              sortedTrackers.map(({ id, name, authorId }) => (
                <div className={styles.sectionItem} key={id}>
                  <Button
                    buttonSize="large"
                    buttonStyle="transparent"
                    className={styles.itemButton}
                    onClick={() => onTrackerClick(id)}
                  >
                    <div className={styles.sectionItemIcon}>
                      <TrackerKeywordsDataSourcesIcon trackerId={id} />
                    </div>
                    <div className={styles.sectionItemName}>
                      <span>{name}</span>
                      <TrackerStatusIcon trackerId={id} />
                    </div>
                  </Button>
                  <div className={styles.sectionItemActionsHover}>
                    <div className={styles.authorWrapper}>
                      <User />
                      <span>{getAuthorFullName(authorId)}</span>
                    </div>
                  </div>
                  <div className={styles.sectionItemActions}>
                    <Button
                      buttonSize="small"
                      buttonStyle="transparent"
                      onClick={() => onTrackerClick(id)}
                      className={styles.itemDeleteButton}
                    >
                      {checkIfTrackerSelected(id) ? (
                        <MinusCircleFill />
                      ) : (
                        <PlusCircleOutline />
                      )}
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.placeholder}>
                {t("page.dashboard.label.no_topics")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Tooltip content={updateButtonTooltip}>
          <Button
            buttonSize="large"
            onClick={onUpdateClicked}
            disabled={isUpdateButtonDisabled}
          >
            <span>{t("page.dashboard.button.update_dashboard")}</span>
            {hasWidgetSync && <Loader className={styles.loader} />}
          </Button>
        </Tooltip>
        <Tooltip content={t("page.dashboard.tooltip.create_dashboard")}>
          <Button
            buttonSize="large"
            disabled={isCreateButtonDisabled}
            onClick={onCreateNewCollectionClicked}
          >
            {t("page.dashboard.button.create_dashboard")}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
