import { FC, useMemo } from "react";
import isString from "lodash/isString";

import styles from "./AssistantVolumeShareLineChartTool.module.scss";
import { LineChart, Loader } from "src/components";
import { formatToLocaleNumber } from "src/utils";
import { RawChartItem } from "src/components/charts/LineChart/types";

// inner imports
import { VolumeShareLineChartTool } from "./types";
import { formatVolumeShareLineChartData } from "./utils";

type Props = Record<string, unknown>;

export const AssistantVolumeShareLineChartTool: FC<Props> = ({
  result,
  dashboardId: defaultDashboardId,
}) => {
  const { data, isLoading } = useMemo<
    VolumeShareLineChartTool & { isLoading: boolean }
  >(() => {
    if (!result) return { dateFrom: "", dateTo: "", data: [], isLoading: true };

    if (!isString(result))
      return { dateFrom: "", dateTo: "", data: [], isLoading: false };

    try {
      const parsedResult = JSON.parse(result);
      return parsedResult
        ? { ...parsedResult, isLoading: false }
        : { dateFrom: "", dateTo: "", data: [], isLoading: false };
    } catch (error) {
      console.error("Failed to parse volume share line chart data:", error);
      return { dateFrom: "", dateTo: "", data: [], isLoading: false };
    }
  }, [result]);

  const formattedTrackers = useMemo<Pick<Tracker.Data, "id" | "name">[]>(
    () =>
      data.map(({ topicId, topicName }) => ({ id: topicId, name: topicName })),
    [data],
  );

  const formattedLineChart = useMemo<RawChartItem[]>(
    () => formatVolumeShareLineChartData(data),
    [data],
  );

  const dashboardId = useMemo<Dashboard.Data["id"]>(() => {
    if (!isString(defaultDashboardId)) return "";

    return defaultDashboardId;
  }, [defaultDashboardId]);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loaderWrapper}>
          <Loader />
          <span className={styles.loaderText}>Loading chart data...</span>
        </div>
      </div>
    );
  }

  if (!data.length) return null;

  return (
    <div className={styles.wrapper}>
      <LineChart
        data={formattedLineChart}
        trackers={formattedTrackers}
        trackersCollectionId={dashboardId}
        chartSettings={{ hasTrendLine: false }}
        axis={{ yAxisValueFormatter: formatToLocaleNumber }}
      />
    </div>
  );
};
