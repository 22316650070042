import { FC, useMemo } from "react";
import isString from "lodash/isString";

import styles from "./AssistantSearchShareLineChartTool.module.scss";
import { LineChart } from "src/components";
import { formatToLocaleNumber } from "src/utils";
import { RawChartItem } from "src/components/charts/LineChart/types";

// inner imports
import { SearchShareLineChartTool } from "./types";
import { formatSearchShareLineChartData } from "./utils";

type Props = Record<string, unknown>;

export const AssistantSearchShareLineChartTool: FC<Props> = ({
  result,
  dashboardId: defaultDashboardId,
}) => {
  const { data } = useMemo<SearchShareLineChartTool>(() => {
    if (!isString(result)) return { dateFrom: "", dateTo: "", data: [] };

    return JSON.parse(result) || { dateFrom: "", dateTo: "", data: [] };
  }, [result]);

  const formattedTrackers = useMemo<Pick<Tracker.Data, "id" | "name">[]>(
    () =>
      data.map(({ topicId, topicName }) => ({ id: topicId, name: topicName })),
    [data],
  );

  const formattedLineChart = useMemo<RawChartItem[]>(
    () => formatSearchShareLineChartData(data),
    [data],
  );

  const dashboardId = useMemo<Dashboard.Data["id"]>(() => {
    if (!isString(defaultDashboardId)) return "";

    return defaultDashboardId;
  }, [defaultDashboardId]);

  if (!data.length) return null;

  return (
    <div className={styles.wrapper}>
      <LineChart
        data={formattedLineChart}
        trackers={formattedTrackers}
        trackersCollectionId={dashboardId}
        chartSettings={{ hasTrendLine: false }}
        axis={{
          yAxisValueFormatter: (value) => `${value}%`,
          yAxisAdditionalValueFormatter: formatToLocaleNumber,
        }}
      />
    </div>
  );
};
