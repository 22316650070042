import {
  useMemo,
  forwardRef,
  useContext,
  useCallback,
  ForwardedRef,
} from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./WidgetTileButtons.module.scss";
import { Expand, Shrink } from "src/assets/icons";
import { Button, MenuDropdown, Tooltip } from "src/components";
import { MenuOption } from "src/components/MenuDropdown/types";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import {
  selectWidgetStatus,
  selectAvailableDashboardById,
  selectIsCompanyTrackersCollection,
} from "src/store/selectors";
import { useWidgetActionButtons } from "../../../Widgets/hooks";
import { WidgetSettings } from "../../../WidgetSettings/WidgetSettings";
import { WidgetViewButtons } from "../../../WidgetViewButtons/WidgetViewButtons";
import { WidgetActionButtons } from "../../../WidgetActionButtons/WidgetActionButtons";

// Inner imports
import {
  useIsWidgetLocked,
  useExportWidgetData,
  useExportWidgetImage,
} from "../../hooks";

type Props = {
  viewIndex: number;
  isReadOnly: boolean;
  views: Widget.ChartView[];
  chartProps: Widget.ChartProps;
  isHovering?: boolean;
  setViewIndex: (index: number) => void;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  setExpandedWidgetId: (value: Widget.IdType | null) => void;
};

export const WidgetTileButtons = forwardRef(
  (
    {
      views,
      viewIndex,
      chartProps,
      isReadOnly,
      isHovering,
      setViewIndex,
      setExpandedWidgetId,
      dashboardDateRangeId,
    }: Props,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) => {
    const { t } = useTranslation();

    const { setSidebarContent } = useContext(DashboardPageContext);

    const { widgetId, trackersCollectionId, isExpanded } =
      useMemo<Widget.ChartProps>(() => chartProps, [chartProps]);

    const widgetStatus = useSelector((state: Store.RootState) =>
      selectWidgetStatus(state, dashboardDateRangeId, widgetId),
    );

    const dashboard = useSelector((state: Store.RootState) =>
      selectAvailableDashboardById(state, trackersCollectionId, isReadOnly),
    );

    const isCompanyTrackersCollection = useSelector((state: Store.RootState) =>
      selectIsCompanyTrackersCollection(state, trackersCollectionId),
    );

    const view = useMemo<Nullable<Widget.ChartView>>(
      () => views[viewIndex] || null,
      [views, viewIndex],
    );

    const isWidgetLocked = useIsWidgetLocked(
      trackersCollectionId,
      widgetId,
      isReadOnly,
    );

    const { hasWidgetDataExport, exportWidgetData } = useExportWidgetData({
      widgetId,
      trackersCollectionId,
      dashboardDateRangeId,
    });

    const { hasWidgetImageExport, exportWidgetImage } = useExportWidgetImage({
      view,
      widgetId,
      isExpanded,
      ref: forwardedRef,
      trackersCollectionId,
      dashboardDateRangeId,
    });

    const widgetActionButtons = useWidgetActionButtons(chartProps);

    const isWidgetButtonsShown = useMemo<boolean>(
      () => widgetStatus === "success" || widgetStatus === "updating",
      [widgetStatus],
    );

    const isExportWidgetDataShown = useMemo<boolean>(
      () => isWidgetButtonsShown && hasWidgetDataExport,
      [hasWidgetDataExport, isWidgetButtonsShown],
    );

    const isExportWidgetImageShown = useMemo<boolean>(
      () => isWidgetButtonsShown && hasWidgetImageExport,
      [hasWidgetImageExport, isWidgetButtonsShown],
    );

    const onWidgetSettingsClick = useCallback(
      (): void =>
        setSidebarContent(
          <WidgetSettings
            dashboardId={trackersCollectionId}
            widgetId={widgetId}
          />,
        ),
      [trackersCollectionId, setSidebarContent, widgetId],
    );

    const widgetMenuOptions = useMemo<MenuOption[]>(() => {
      const options: MenuOption[] = [];

      if (!dashboard) return options;

      if (isExportWidgetDataShown)
        options.push({
          icon: "FileXls",
          label: t("component.widget_tile.button.download_excel"),
          onClick: exportWidgetData,
        });

      if (isExportWidgetImageShown)
        options.push(
          {
            icon: "FilePng",
            label: t("component.widget_tile.button.download_png"),
            onClick: () => exportWidgetImage("png"),
          },
          {
            icon: "FileSvg",
            label: t("component.widget_tile.button.download_svg"),
            onClick: () => exportWidgetImage("svg"),
          },
        );

      if (isCompanyTrackersCollection && !isReadOnly)
        options.push({
          icon: "Gear",
          label: t("component.widget_tile.button.widget_settings"),
          onClick: onWidgetSettingsClick,
        });

      return options;
    }, [
      t,
      dashboard,
      isReadOnly,
      exportWidgetData,
      exportWidgetImage,
      onWidgetSettingsClick,
      isExportWidgetDataShown,
      isExportWidgetImageShown,
      isCompanyTrackersCollection,
    ]);

    const isWidgetMenuShown = useMemo<boolean>(
      () => Boolean(widgetMenuOptions.length),
      [widgetMenuOptions.length],
    );

    const onOpenClickHandler = (): void =>
      setExpandedWidgetId(isExpanded ? null : widgetId);

    return (
      <div
        className={cx(
          styles.wrapper,
          isWidgetLocked ? styles.locked : "",
          isHovering ? styles.parentHover : "",
        )}
      >
        {isWidgetButtonsShown && (
          <WidgetActionButtons buttons={widgetActionButtons} />
        )}
        {isWidgetButtonsShown && (
          <WidgetViewButtons
            widgetViews={views}
            viewIndex={viewIndex}
            setViewIndex={setViewIndex}
          />
        )}
        <Tooltip content={t("component.widget_tile.tooltip.widget_resize")}>
          <Button
            className={styles.button}
            onClick={onOpenClickHandler}
            buttonStyle="transparent"
            buttonSize="small"
          >
            {isExpanded ? <Shrink /> : <Expand />}
          </Button>
        </Tooltip>
        {isWidgetMenuShown && (
          <MenuDropdown
            className={styles.widgetMenu}
            options={widgetMenuOptions}
            ref={forwardedRef}
            isPositionFixed
          />
        )}
      </div>
    );
  },
);
