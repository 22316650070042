import { useCallback } from "react";
import { useAssistantToolUI } from "@assistant-ui/react";

// Inner imports
import { AssistantVolumeSharePieChartTool } from "../components";

type Props = {
  configuration?: Record<string, unknown>;
};

export const useAssistantVolumeSharePieChartTool = ({}: Props) =>
  useAssistantToolUI({
    toolName: "generate_search_volume_pie_chart_tool",
    render: useCallback(AssistantVolumeSharePieChartTool, []),
  });
