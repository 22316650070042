import { FC, useCallback, useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

import styles from "./DashboardAgent.module.scss";
import { Agent } from "src/features";
import { getHostDomain } from "src/utils";
import { Button, Tooltip } from "src/components";
import { PencilSquareOutline, ChatAi, Cross } from "src/assets/icons";
import {
  selectUserId,
  selectCompanyId,
  selectWhiteLabel,
  selectTrackersById,
  selectAvailableDashboardById,
  selectTrackersCollectionById,
} from "src/store/selectors";
import { useWindowSize } from "../DashboardPageWrapper/hooks";

type Props = {
  dashboardId: Dashboard.Data["id"];
  isReadOnly: boolean;
  isExpanded: boolean;
  setIsExpandedHandler: (value: boolean) => void;
};

const DashboardAgent: FC<Props> = ({
  isReadOnly,
  isExpanded,
  dashboardId,
  setIsExpandedHandler,
}) => {
  const { t } = useTranslation();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, isReadOnly),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, dashboardId),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackersCollection?.trackerIds || []),
  );

  const whiteLabel = useSelector(selectWhiteLabel);

  // State for tracking current thread ID
  const [_, setThreadId] = useState<string>("");

  // Use a reset key to force the Agent component to remount on new thread
  const [resetKey, setResetKey] = useState(0);

  const trackerMetadata = useMemo<
    { topicId: Tracker.Data["id"]; topicName: Tracker.Data["name"] }[]
  >(
    () => trackers.map(({ id, name }) => ({ topicId: id, topicName: name })),
    [trackers],
  );

  const { width } = useWindowSize();
  const isMobile = width ? width <= 768 : false;
  const initializedRef = useRef(false);

  // Set initial expanded state based on device type only once when component mounts
  useEffect(() => {
    // The parent component (DashboardPageWrapper) now handles the initial state
    // This useEffect is no longer needed, but we'll keep the ref for potential future use
    initializedRef.current = true;
  }, []);

  const onSelectThreadId = (value: Thread.Data["id"]): void =>
    setThreadId(value);

  // Simple function to start a new thread - force component remount
  const onStartNewThread = useCallback((): void => {
    setIsExpandedHandler(true);
    setResetKey((prev) => prev + 1);
  }, [setIsExpandedHandler]);

  const onExpandChat = useCallback(
    (): void => setIsExpandedHandler(true),
    [setIsExpandedHandler],
  );

  const onCollapseChat = useCallback(
    (): void => setIsExpandedHandler(false),
    [setIsExpandedHandler],
  );

  useEffect(() => {
    // Clean up any existing mobile button containers
    const existingContainer = document.getElementById(
      "mobile-button-container",
    );
    if (existingContainer) {
      try {
        document.body.removeChild(existingContainer);
      } catch (error) {
        console.error("Error removing mobile container:", error);
      }
    }
  }, []);

  // Mobile expand button component
  const MobileExpandButton = useMemo(() => {
    if (!isMobile || isExpanded) return null;

    return ReactDOM.createPortal(
      <div
        className={styles.mobileExpandButtonContainer}
        style={{
          position: "fixed",
          top: "9em",
          right: "1.5em",
          zIndex: 10,
        }}
      >
        <Button
          buttonStyle="transparent"
          onClick={onExpandChat}
          className={`${styles.mobileExpandButton} ${styles.iconButton}`}
          style={{
            width: "auto",
            height: "40px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 15px",
            background: "white",
            border: "1px solid #eaeaea",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            gap: "8px",
          }}
        >
          <ChatAi
            size={15}
            style={{
              display: "block",
              fill: "currentColor",
              strokeWidth: 0,
              width: "15px",
              height: "15px",
            }}
            color="var(--color-primary)"
          />
          <span style={{ color: "var(--color-primary)", fontWeight: "500" }}>
            Ask
          </span>
        </Button>
      </div>,
      document.body,
    );
  }, [isMobile, isExpanded, onExpandChat]);

  if (!isExpanded)
    return (
      <>
        {!isMobile && (
          <div
            className={styles.agentWrapper}
            data-collapsed={!isExpanded}
            data-expanded={isExpanded}
          >
            <div className={styles.collapsedWrapper}>
              <div className={styles.collapsedButtonsContainer}>
                <div className={styles.collapsedButton}>
                  <Tooltip
                    content={t(
                      "component.agent.dashboard_agent.button.start_conversation",
                    )}
                  >
                    <Button
                      buttonStyle="transparent"
                      onClick={onStartNewThread}
                      className={`${styles.addThread} ${styles.iconButton}`}
                    >
                      <PencilSquareOutline
                        size={15}
                        style={{ width: "15px", height: "15px" }}
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className={styles.collapsedButton}>
                  <Tooltip content="Show chat">
                    <Button
                      onClick={onExpandChat}
                      buttonStyle="transparent"
                      className={`${styles.expandCollapseButton} ${styles.iconButton}`}
                    >
                      <ChatAi
                        size={15}
                        style={{
                          display: "block",
                          fill: "currentColor",
                          strokeWidth: 0,
                          width: "15px",
                          height: "15px",
                        }}
                        color="var(--color-primary)"
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
        {MobileExpandButton}
      </>
    );

  return (
    <div
      className={styles.agentWrapper}
      data-collapsed={!isExpanded}
      data-expanded={isExpanded}
    >
      <div className={styles.threadList}>
        <div className={styles.threadListLeftGroup}>
          {/* Thread history dropdown removed */}
          <Tooltip
            content={t(
              "component.agent.dashboard_agent.button.start_conversation",
            )}
          >
            <Button
              buttonStyle="transparent"
              onClick={onStartNewThread}
              className={`${styles.addThread} ${styles.iconButton}`}
            >
              <PencilSquareOutline
                size={15}
                style={{ width: "15px", height: "15px" }}
              />
            </Button>
          </Tooltip>
        </div>

        <div className={styles.threadListRightGroup}>
          {/* Add mobile collapse button to the right group */}
          {isMobile && (
            <Tooltip
              content={t(
                "component.agent.dashboard_agent.button.hide_conversation",
              )}
            >
              <Button
                onClick={onCollapseChat}
                buttonStyle="transparent"
                className={`${styles.closeChat} ${styles.iconButton}`}
              >
                <Cross size={15} style={{ width: "15px", height: "15px" }} />
              </Button>
            </Tooltip>
          )}

          {!isMobile && (
            <div className={styles.closeChatWrapper}>
              <Tooltip
                content={t(
                  "component.agent.dashboard_agent.button.hide_conversation",
                )}
              >
                <Button
                  onClick={onCollapseChat}
                  buttonStyle="transparent"
                  className={`${styles.closeChat} ${styles.iconButton}`}
                >
                  <Cross size={15} style={{ width: "15px", height: "15px" }} />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <Agent
        key={`dashboard-agent-${resetKey}`}
        threadId=""
        agentFlow="talk_to_the_data"
        selectThreadId={onSelectThreadId}
        configuration={{
          companyId,
          dashboardId,
          authorId: userId,
          topics: trackerMetadata,
          dashboardName: dashboard?.name,
          whiteLabelName: whiteLabel.name,
          whiteLabelDomainId: getHostDomain(),
        }}
      />
    </div>
  );
};

export default DashboardAgent;
