import { FC, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SelectTrackersSection.module.scss";
import { useAppDispatch } from "src/store";
import { generateDocId } from "src/store/utils";
import { showToastNotification } from "src/utils";
import { ArrowBoldLeft } from "src/assets/icons";
import { SelectTrackerSearchesModal } from "src/features";
import { Button, Transition, Form, Preloader } from "src/components";
import { createTrackersWithSearches } from "src/store/trackers/trackersApi";
import { createDashboardWithTrackers } from "src/store/dashboards/dashboardsApi";
import {
  GREEN,
  ROUTES,
  DEFAULT_TRACKER_CATEGORY,
  DEFAULT_TRACKER_PERSPECTIVE,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";
import {
  useModal,
  useLocationId,
  useLanguageId,
  useQueryParams,
  useGlobalPreloader,
  useKeywordsDataSource,
  useResetScrollPosition,
} from "src/hooks";
import {
  selectUserId,
  selectCompanyId,
  selectCompanyTrackers,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  fetchTrackersByIds,
  fetchDashboardById,
  updateLatestTrackerConfig,
  fetchTrackersCollectionById,
  updateUserLastViewedTrackerIds,
} from "src/store/actions";
import { CreateTrackerStep } from "../../types";

// Inner imports
import { formatSelectedTrackers } from "./utils";
import { useSelectedTrackers, useSelectedSearches } from "./hooks";
import {
  SelectedCategory,
  SelectedTrackers,
  SuggestedTrackers,
} from "./components";

type Props = {
  category: Tracker.Category | null;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  dashboard?: Dashboard.Data;
  stepBackHandler: (value: CreateTrackerStep) => void;
  selectedSearches: Record<Tracker.Data["id"], Search.CreationData[]>;
  selectedTrackers: Tracker.CreationData[];
  suggestedTrackers: Record<Tracker.Perspective, Tracker.CreationData[]>;
  keywordsDataSource: Search.KeywordsDataSource | null;
  suggestedPerspectives: Tracker.Perspective[];
};

export const SelectTrackersSection: FC<Props> = ({
  dashboard,
  stepBackHandler,
  category: defaultCategory,
  locationId: defaultLocationId,
  languageId: defaultLanguageId,
  selectedSearches: defaultSelectedSearches,
  selectedTrackers: defaultSelectedTrackers,
  suggestedTrackers: defaultSuggestedTrackers,
  keywordsDataSource: defaultKeywordsDataSource,
  suggestedPerspectives: defaultSuggestedPerspectives,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { topics: trackerIdsString = "" } = useQueryParams();

  const { setModal } = useModal();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const { selectedTrackers, selectTracker, updateTracker, unselectTracker } =
    useSelectedTrackers({ defaultSelectedTrackers });

  const { selectedSearches, selectSearch, selectSearches, resetSearches } =
    useSelectedSearches({ defaultSelectedSearches });

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const locations = useSelector(selectLocationsEntities);

  const trackers = useSelector(selectCompanyTrackers);

  const [suggestedTrackers, setSuggestedTrackers] = useImmer<
    Record<Tracker.Perspective, Tracker.CreationData[]>
  >(defaultSuggestedTrackers);

  const [suggestedPerspectives, setSuggestedPerspectives] = useState<
    Tracker.Perspective[]
  >(defaultSuggestedPerspectives);

  const [perspective, setPerspective] = useState<Tracker.Perspective>(
    DEFAULT_TRACKER_PERSPECTIVE,
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const [keywordsDataSource, setKeywordsDataSource] = useKeywordsDataSource(
    defaultKeywordsDataSource,
  );

  const [locationId, setLocationId] = useLocationId({
    locationId: defaultLocationId,
    keywordsDataSources: [keywordsDataSource],
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    languageId: defaultLanguageId,
    keywordsDataSources: [keywordsDataSource],
  });

  const category = useMemo<Tracker.Category>(() => {
    if (defaultCategory) return defaultCategory;

    if (dashboard)
      return {
        subject: dashboard.name,
        category: dashboard.name,
        description: dashboard.description || "",
      };

    return { subject: "", description: "", category: "" };
  }, [dashboard, defaultCategory]);

  const hasDashboard = useMemo<boolean>(() => Boolean(dashboard), [dashboard]);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isEverySearchCreated = useMemo<boolean>(() => {
    for (const { id: trackerId } of selectedTrackers) {
      const searches = selectedSearches[trackerId];

      if (!searches) return false;
    }

    return true;
  }, [selectedSearches, selectedTrackers]);

  const isSubmitShown = useMemo<boolean>(
    () => Boolean(selectedTrackers.length) && !isLoading,
    [selectedTrackers.length, isLoading],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () =>
      Boolean(selectedTrackers.length) && !isLoading && isEverySearchCreated,
    [isEverySearchCreated, isLoading, selectedTrackers.length],
  );

  const isFooterShown = useMemo<boolean>(
    () => Boolean(selectedTrackers.length) || hasDashboard,
    [selectedTrackers.length, hasDashboard],
  );

  const isGoBackAvailable = useMemo<boolean>(() => !isLoading, [isLoading]);

  const submitLabel = useMemo<string>(() => {
    if (hasDashboard)
      return t(
        "page.create_tracker.select_trackers.form.button.create_trackers",
      );

    return t(
      "page.create_tracker.select_trackers.form.button.create_dashboard",
    );
  }, [hasDashboard, t]);

  const nextStepLabel = useMemo<string>(() => {
    if (hasDashboard)
      return t("page.create_tracker.select_trackers.label.create_trackers");

    return t("page.create_tracker.select_trackers.label.create_dashboard");
  }, [hasDashboard, t]);

  const usedTrackerIds = useMemo<string[]>(
    () => trackerIdsString.split(","),
    [trackerIdsString],
  );

  useResetScrollPosition();

  const openTrackerSettings = (value: Tracker.CreationData): void => {
    if (!locationId || !languageId || !keywordsDataSource) return;

    const trackerSelectedSearches = selectedSearches[value.id] || [];

    setModal(
      "select-tracker-searches",
      <SelectTrackerSearchesModal
        tracker={value}
        locationId={locationId}
        languageId={languageId}
        submitHandler={selectSearches}
        selectedTrackers={selectedTrackers}
        selectedSearches={trackerSelectedSearches}
        keywordsDataSource={keywordsDataSource}
      />,
    );
  };

  const selectCustomTracker = (value: Tracker.Data["name"]): void => {
    if (!locationId || !languageId || !keywordsDataSource) return;

    selectTracker({
      languageId,
      locationId,
      name: value,
      description: "",
      id: generateDocId(),
      category: DEFAULT_TRACKER_CATEGORY,
      keywordsDataSources: [keywordsDataSource],
    });
  };

  const selectPerspective = (value: Tracker.Perspective): void =>
    setPerspective(value);

  const updateSuggestedTrackers = (
    selectedPerspective: Tracker.Perspective = perspective,
    value: Tracker.CreationData[],
  ): void =>
    setSuggestedTrackers((draft) => {
      draft[selectedPerspective] = value;
    });

  const createDashboard = async (): Promise<void> => {
    if (!isSubmitAvailable) return;

    try {
      setLoadingStatus("loading");

      const latestSelectedTracker =
        selectedTrackers[selectedTrackers.length - 1];

      const formattedTrackers = await formatSelectedTrackers({
        t,
        dispatch,
        trackers,
        locations,
        selectedSearches,
        selectedTrackers,
      });

      const { dashboardId, trackerIds } = await createDashboardWithTrackers({
        userId,
        category,
        companyId,
        trackers: formattedTrackers,
      });

      await Promise.all([
        dispatch(fetchTrackersByIds(trackerIds)).unwrap(),
        dispatch(fetchDashboardById(dashboardId)).unwrap(),
        dispatch(fetchTrackersCollectionById(dashboardId)).unwrap(),
      ]);

      dispatch(
        updateUserLastViewedTrackerIds({ lastViewedTrackerIds: trackerIds }),
      )
        .unwrap()
        .catch();

      if (latestSelectedTracker)
        dispatch(
          updateLatestTrackerConfig({
            locationId: latestSelectedTracker.locationId,
            languageId: latestSelectedTracker.languageId,
            keywordsDataSource:
              latestSelectedTracker.keywordsDataSources[0] ||
              SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
          }),
        );

      setLoadingStatus("succeeded");

      history.push(`${ROUTES.dashboardsHomePage}/${dashboardId}`);
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const createTrackers = async (): Promise<void> => {
    if (!isSubmitAvailable) return;

    try {
      showGlobalPreloader(
        t("page.create_tracker.select_trackers.loader.create_topics"),
      );

      const latestSelectedTracker =
        selectedTrackers[selectedTrackers.length - 1];

      const formattedTrackers = await formatSelectedTrackers({
        t,
        dispatch,
        trackers,
        locations,
        selectedSearches,
        selectedTrackers,
      });

      const { trackerIds } = await createTrackersWithSearches({
        userId,
        companyId,
        trackers: formattedTrackers,
        dashboardName: category.subject,
        dashboardDescription: category.description,
      });

      await dispatch(fetchTrackersByIds(trackerIds)).unwrap();

      dispatch(
        updateUserLastViewedTrackerIds({ lastViewedTrackerIds: trackerIds }),
      )
        .unwrap()
        .catch();

      if (latestSelectedTracker)
        dispatch(
          updateLatestTrackerConfig({
            locationId: latestSelectedTracker.locationId,
            languageId: latestSelectedTracker.languageId,
            keywordsDataSource:
              latestSelectedTracker.keywordsDataSources[0] ||
              SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
          }),
        );

      history.push(`${ROUTES.dashboardsHomePage}/${dashboard?.id}`, {
        topicIds: [...usedTrackerIds, ...trackerIds],
        isAddCompareSidebarOpen: true,
      });
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    } finally {
      hideGlobalPreloader();
    }
  };

  const onSubmit = (): Promise<void> => {
    if (dashboard) return createTrackers();

    return createDashboard();
  };

  const onCancel = (): void => history.goBack();

  const stepBack = (): void => stepBackHandler("trackers");

  if (!locationId || !languageId) return null;

  return (
    <Form
      onSubmit={onSubmit}
      className={styles.wrapper}
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>
            <Button
              onClick={stepBack}
              disabled={!isGoBackAvailable}
              buttonSize="medium"
            >
              <ArrowBoldLeft />
            </Button>
            <span className={styles.heading}>
              {t("page.create_tracker.select_trackers.heading")}
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <SelectedCategory category={category} />
        </div>
        <div className={styles.section}>
          <SuggestedTrackers
            category={category}
            languageId={languageId}
            locationId={locationId}
            perspective={perspective}
            selectedTrackers={selectedTrackers}
            suggestedTrackers={suggestedTrackers}
            keywordsDataSource={
              keywordsDataSource || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE
            }
            selectTrackerHandler={selectTracker}
            suggestedPerspectives={suggestedPerspectives}
            unselectTrackerHandler={unselectTracker}
            selectLocationIdHandler={setLocationId}
            selectLanguageIdHandler={setLanguageId}
            selectPerspectiveHandler={selectPerspective}
            updateSuggestedTrackersHandler={updateSuggestedTrackers}
            selectKeywordsDataSourceHandler={setKeywordsDataSource}
            updateSuggestedPerspectivesHandler={setSuggestedPerspectives}
          />
        </div>
        <div className={styles.section}>
          <SelectedTrackers
            selectedTrackers={selectedTrackers}
            selectedSearches={selectedSearches}
            selectSearchHandler={selectSearch}
            resetSearchesHandler={resetSearches}
            updateTrackerHandler={updateTracker}
            selectTrackerHandler={selectTracker}
            unselectTrackerHandler={unselectTracker}
            duplicateTrackerHandler={selectTracker}
            selectCustomTrackerHandler={selectCustomTracker}
            openTrackerSettingsHandler={openTrackerSettings}
          />
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Transition
          in={isFooterShown}
          classNames={{
            exit: styles.slideOutBottom,
            enter: styles.slideInBottom,
          }}
        >
          <div className={styles.footer}>
            <div>
              <div className={styles.nextStep}>
                <Button
                  onClick={stepBack}
                  disabled={!isGoBackAvailable}
                  buttonSize="small"
                >
                  <ArrowBoldLeft />
                </Button>
                <span>
                  {t("page.create_tracker.select_trackers.label.next_step")}
                </span>
                <span>{nextStepLabel}</span>
                {isLoading && <Preloader type="beat" color={GREEN} />}
              </div>
              <div className={styles.submit}>
                <Transition
                  in={hasDashboard && !isLoading}
                  classNames={{ exit: styles.slideOutRight }}
                >
                  <Button
                    onClick={onCancel}
                    disabled={isLoading}
                    className={styles.cancelButton}
                    buttonSize="medium"
                    buttonStyle="transparent"
                  >
                    <span>
                      {t(
                        "page.create_tracker.select_trackers.form.button.cancel",
                      )}
                    </span>
                  </Button>
                </Transition>
                <Transition
                  in={isSubmitShown && !isLoading}
                  classNames={{
                    exit: styles.slideOutRight,
                    enter: isSubmitShown ? styles.slideInRight : "",
                  }}
                >
                  <Button
                    type="submit"
                    autoFocus
                    disabled={!isSubmitAvailable}
                    buttonSize="medium"
                    className={styles.submitButton}
                  >
                    <span>{submitLabel}</span>
                  </Button>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Form>
  );
};
