import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./EnterQuerySection.module.scss";
import { GREEN } from "src/constants";
import { selectApplicationInfo, selectWhiteLabel } from "src/store/selectors";
import { LocationsDropdown, KeywordsDataSourcesDropdown } from "src/features";
import { Button, Form, Preloader, Textarea, Transition } from "src/components";
import {
  useLanguageId,
  useLocationId,
  useUnmountEffect,
  useTemporaryErrors,
  useKeywordsDataSource,
} from "src/hooks";
import { useGetSuggestedCategories } from "../../hooks";

// Inner imports
import { CREATE_TRACKERS_QUERY_INPUT_LIMIT } from "./constants";

type Props = {
  query: string;
  dashboard?: Dashboard.Data;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource | null;
  submitHandler: (value: {
    query: string;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    categories: Tracker.Category[];
    keywordsDataSource: Search.KeywordsDataSource;
  }) => void;
};

export const EnterQuerySection: FC<Props> = ({
  dashboard,
  submitHandler,
  query: defaultQuery,
  locationId: selectedLocationId,
  languageId: defaultLanguageId,
  keywordsDataSource: selectedKeywordsDataSource,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    latestTrackerConfig: {
      locationId: latestLocationId,
      keywordsDataSource: latestKeywordsDataSource,
    },
  } = useSelector(selectApplicationInfo);

  const whiteLabel = useSelector(selectWhiteLabel);

  const { errors, setErrors } = useTemporaryErrors();

  const defaultLocationId = useMemo<Location.Data["id"] | null>(
    () => selectedLocationId || latestLocationId,
    [latestLocationId, selectedLocationId],
  );

  const defaultKeywordsDataSource = useMemo<Search.KeywordsDataSource | null>(
    () => selectedKeywordsDataSource || latestKeywordsDataSource,
    [latestKeywordsDataSource, selectedKeywordsDataSource],
  );

  const [keywordsDataSource, setKeywordsDataSource] = useKeywordsDataSource(
    defaultKeywordsDataSource,
  );

  const [locationId, setLocationId] = useLocationId({
    locationId: defaultLocationId,
    keywordsDataSources: [keywordsDataSource],
  });

  const [languageId] = useLanguageId({
    locationId,
    languageId: defaultLanguageId,
    keywordsDataSources: [keywordsDataSource],
  });

  const [query, setQuery] = useState<string>(defaultQuery);

  const [suggestedCategoriesStatus, setSuggestedCategoriesStatus] =
    useState<LoadingStatus>("idle");

  const hasDashboard = useMemo<boolean>(() => Boolean(dashboard), [dashboard]);

  const isSuggestedCategoriesLoading = useMemo<boolean>(
    () => suggestedCategoriesStatus === "loading",
    [suggestedCategoriesStatus],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () => Boolean(query && locationId && languageId && keywordsDataSource),
    [query, locationId, languageId, keywordsDataSource],
  );

  const isFooterShown = useMemo<boolean>(
    () => isSubmitAvailable || hasDashboard,
    [isSubmitAvailable, hasDashboard],
  );

  const { getSuggestedCategories, cancelGetSuggestedCategories } =
    useGetSuggestedCategories({
      status: suggestedCategoriesStatus,
      updateStatusHandler: setSuggestedCategoriesStatus,
    });

  useUnmountEffect(cancelGetSuggestedCategories);

  const onCancel = (): void => history.goBack();

  const onSubmit = (): void => {
    if (
      !isSubmitAvailable ||
      !locationId ||
      !languageId ||
      !keywordsDataSource
    ) {
      const errors: Errors = {};

      if (!keywordsDataSource)
        errors.keywordsDataSource = t(
          "page.create_tracker.enter_query.validation.data_source_required",
        );

      if (!locationId)
        errors.locationId = t(
          "page.create_tracker.enter_query.validation.location_required",
        );

      return setErrors(errors);
    }

    getSuggestedCategories({
      query,
      locationId,
      callback: (categories) =>
        submitHandler({
          query,
          locationId,
          languageId,
          categories,
          keywordsDataSource,
        }),
    }).catch();
  };

  const selectKeywordsDataSource = (value: Search.KeywordsDataSource): void => {
    setErrors((state) => ({ ...state, keywordsDataSource: "" }));

    setKeywordsDataSource(value);
  };

  const selectLocationId = (value: Location.Data["id"]): void => {
    setErrors((state) => ({ ...state, locationId: "" }));

    setLocationId(value);
  };

  return (
    <Form
      onSubmit={onSubmit}
      className={styles.wrapper}
      style={{
        width: "100%",
        maxWidth: "100%",
        overflow: "visible",
      }}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.logoContainer}>
            <img
              alt={whiteLabel.name}
              src={whiteLabel.logos.small}
              className={styles.logo}
            />
          </div>

          <div className={styles.title}>
            <div className={styles.subTitle}>
              <span className={styles.heading}>
                {dashboard
                  ? t(
                      "page.create_tracker.enter_query.heading_with_dashboard",
                      { dashboardName: dashboard.name },
                    )
                  : t("page.create_tracker.enter_query.heading")}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <Textarea
            value={query}
            disabled={isSuggestedCategoriesLoading}
            autoFocus
            className={styles.textareaWrapper}
            textareaClassName={styles.textarea}
            changeHandler={setQuery}
            characterLimit={CREATE_TRACKERS_QUERY_INPUT_LIMIT}
            placeholder={t(
              "page.create_tracker.enter_query.form.placeholder.analyse",
            )}
          />
        </div>

        <div className={styles.configurations}>
          <div>
            <KeywordsDataSourcesDropdown
              error={errors.keywordsDataSource}
              isDisabled={isSuggestedCategoriesLoading}
              dataSource={keywordsDataSource}
              setDataSource={selectKeywordsDataSource}
            />
          </div>

          <div>
            <LocationsDropdown
              error={errors.locationId}
              isDisabled={isSuggestedCategoriesLoading}
              locationId={locationId}
              setLocationId={selectLocationId}
              keywordsDataSources={[keywordsDataSource]}
              placement="bottomStart"
              placeholder={t(
                "page.create_tracker.enter_query.form.placeholder.location",
              )}
            />
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Transition
          in={isFooterShown}
          classNames={{
            exit: styles.slideOutBottom,
            enter: styles.slideInBottom,
          }}
        >
          <div className={styles.footer}>
            <div>
              <div className={styles.nextStep}>
                <span>
                  {t("page.create_tracker.enter_query.label.next_step")}
                </span>
                <span>
                  {t("page.create_tracker.enter_query.label.select_category")}
                </span>
                {isSuggestedCategoriesLoading && (
                  <Preloader type="beat" color={GREEN} />
                )}
              </div>
              <div className={styles.submit}>
                <Transition
                  in={hasDashboard && !isSuggestedCategoriesLoading}
                  classNames={{ exit: styles.slideOutRight }}
                >
                  <Button
                    onClick={onCancel}
                    disabled={isSuggestedCategoriesLoading}
                    className={styles.cancelButton}
                    buttonSize="large"
                    buttonStyle="transparent"
                  >
                    <span>
                      {t("page.create_tracker.enter_query.form.button.cancel")}
                    </span>
                  </Button>
                </Transition>
                <Transition
                  in={isSubmitAvailable && !isSuggestedCategoriesLoading}
                  classNames={{
                    exit: styles.slideOutRight,
                    enter: isSubmitAvailable ? styles.slideInRight : "",
                  }}
                >
                  <Button
                    type="submit"
                    disabled={!isSubmitAvailable}
                    className={styles.submitButton}
                    buttonSize="large"
                  >
                    <span>
                      {t("page.create_tracker.enter_query.form.button.submit")}
                    </span>
                  </Button>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Form>
  );
};
