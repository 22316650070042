import { FC } from "react";
import {
  useAssistantSearchSharePieChartTool,
  useAssistantVolumeSharePieChartTool,
  useAssistantSearchShareLineChartTool,
  useAssistantVolumeShareLineChartTool,
} from "./hooks";

type Props = {
  configuration?: Record<string, unknown>;
};

export const AssistantTools: FC<Props> = (props) => {
  useAssistantSearchSharePieChartTool(props);
  useAssistantVolumeSharePieChartTool(props);
  useAssistantSearchShareLineChartTool(props);
  useAssistantVolumeShareLineChartTool(props);

  return null;
};
