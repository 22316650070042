export const AGENT_THREAD_FLOW: Record<
  Thread.AgentId,
  {
    id: Thread.Data["graphId"];
    suggestions: Array<string | { title: string; prompt: string }>;
    welcomeMessage: string;
    inputPlaceholder?: string;
    welcomeSubMessages?: string[];
    suggestionCountToShow: number;
    flowTypes?: Record<string, string>;
    alignInputIfEmpty?: boolean;
  }
> = {
  talk_to_the_data: {
    id: "talk_to_the_data",
    welcomeMessage: "component.agent.talk_to_data.title",
    welcomeSubMessages: [
      "component.agent.talk_to_data.subtitle.dashboard_name",
      // "component.agent.talk_to_data.subtitle.discover",
    ],
    inputPlaceholder: "component.agent.talk_to_data.input.ask_followup",
    suggestionCountToShow: 4,
    suggestions: [
      {
        title: "Biggest YoY Growth",
        prompt: "Who had the biggest YoY growth in share of search",
      },
      {
        title: "Seasonal Event Impact",
        prompt: "Impact of seasonal events on searches",
      },
      {
        title: "News Impact",
        prompt:
          "Investigate the impact of news on search patterns last three months",
      },
      {
        title: "YoY Changes",
        prompt: "Tell me about the biggest year-over-year search changes",
      },
      {
        title: "Current Trends",
        prompt: "What is trending the most right now",
      },
      {
        title: "Peak Analysis",
        prompt: "Analyze the search peaks and their primary drivers",
      },
      {
        title: "Market Sentiment",
        prompt: "What are the sentiment factors driving search engagement",
      },
      {
        title: "Top Search Terms",
        prompt: "Investigate the top 3 highest-volume search terms",
      },
      {
        title: "Biggest Recent Growth",
        prompt: "Analyze the biggest search volume growth from previous month",
      },
      {
        title: "Seasonal Patterns",
        prompt:
          "What are the seasonal spikes in search interest last three years",
      },
      {
        title: "Peak Months",
        prompt: "Investigate the top 5 peak months for search volume",
      },
      {
        title: "Trending Terms",
        prompt: "Analyze top trending search terms over the last three months",
      },
      {
        title: "Quarterly Leaders",
        prompt: "What are the topics that dominate this quarter",
      },
      {
        title: "Competitor Gains",
        prompt:
          "Analyze which competitors gained the most search interest the last six months",
      },
      {
        title: "Topic Comparison",
        prompt: "What are the search interest differences between top topics",
      },
    ],
  },
  research: {
    id: "research",
    welcomeMessage: "component.agent.research.title",
    inputPlaceholder: "component.agent.research.input.analyze",
    suggestionCountToShow: 4,
    suggestions: [
      {
        title: "Trending Cars in U.S.",
        prompt: "What are the most trending cars in the U.S.?",
      },
      {
        title: "Famous Songs Interest",
        prompt: "How is the interest in the most famous songs of all time?",
      },
      {
        title: "Indian B2B Tech Companies",
        prompt: "Examine brand interest for top 5 Indian B2B tech companies",
      },
      {
        title: "Popular AI Chatbots",
        prompt: "Most popular AI chatbots globally",
      },
    ],
    flowTypes: {
      "Analyze brand awareness and performance metrics for our consumer product brand in the market":
        "brand1",
      "What are the brand awareness and performance indicators for our B2B SaaS platform in the industry":
        "brand2",
      "Investigate emerging trends and patterns in our consumer product category":
        "brand3",
      "Tell me about the emerging trends and patterns in the B2B SaaS market category":
        "brand4",
    },
    alignInputIfEmpty: true,
  },
};
