import { FC, PropsWithChildren, useMemo } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import styles from "./CreateTrackersStep.module.scss";
import { Transition } from "src/components";
import { CreateTrackerStep } from "../../types";
import { ChatAi } from "src/assets/icons";
import { ROUTES } from "src/constants";

type Props = PropsWithChildren & {
  step: CreateTrackerStep;
  currentStep: CreateTrackerStep;
  dashboard?: Dashboard.Data | null;
  className?: string;
};

// const TRANSITION_TIMEOUT: number = 500;

export const CreateTrackersStep: FC<Props> = ({
  step,
  children,
  currentStep,
  className,
  dashboard,
}) => {
  // const previousCurrentStep = usePreviousState(currentStep);

  const isCurrentStep = useMemo<boolean>(
    () => step === currentStep,
    [currentStep, step],
  );

  // const stepIndex = useMemo<number>(
  //   () => CREATE_TRACKERS_STEPS.indexOf(step),
  //   [step],
  // );

  // const nextStep = useMemo<CreateTrackerStep>(
  //   () => CREATE_TRACKERS_STEPS[stepIndex + 1] || CREATE_TRACKERS_DEFAULT_STEP,
  //   [stepIndex],
  // );

  // const enterClassName = useMemo<string | undefined>(
  //   () =>
  //     previousCurrentStep === nextStep
  //       ? styles.slideInTop
  //       : styles.slideInBottom,
  //   [nextStep, previousCurrentStep],
  // );

  // const exitClassName = useMemo<string | undefined>(
  //   () =>
  //     currentStep === nextStep ? styles.slideOutTop : styles.slideOutBottom,
  //   [currentStep, nextStep],
  // );

  if (!isCurrentStep) return null;

  return (
    <Transition
      in={isCurrentStep}
      timeout={0}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: styles.slideInBottom,
        exit: styles.slideOutBottom,
      }}
    >
      <div className={cx(styles.wrapper, className)}>
        {!dashboard && step === "query" && (
          <div className={styles.headerContainer}>
            <div className={styles.generateTrackerLink}>
              <Link
                to={ROUTES.generateTracker}
                className={styles.generateButton}
              >
                <ChatAi size={15} className={styles.chatAiIcon} />
                <span>Generate with Assistant</span>
              </Link>
            </div>
          </div>
        )}
        {children}
      </div>
    </Transition>
  );
};
