import { useCallback } from "react";
import { useAssistantToolUI } from "@assistant-ui/react";

// Inner imports
import { AssistantVolumeShareLineChartTool } from "../components";

type Props = {
  configuration?: Record<string, unknown>;
};

export const useAssistantVolumeShareLineChartTool = ({
  configuration,
}: Props) =>
  useAssistantToolUI({
    toolName: "generate_search_volume_line_chart_tool",
    render: useCallback(
      (input) => (
        <AssistantVolumeShareLineChartTool
          {...input}
          dashboardId={configuration?.dashboardId}
        />
      ),
      [configuration?.dashboardId],
    ),
  });
