import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useWindowSize } from "src/hooks";
import { isProductionEnv } from "src/utils";
import { selectWhiteLabel } from "src/store/selectors";

// List of white label IDs where Intercom should be hidden
const HIDDEN_INTERCOM_WHITE_LABELS = [
  "supreme",
  // Add more white label IDs here
];

export const useIntercomVisibility = () => {
  const { isMobileView } = useWindowSize();
  const whiteLabel = useSelector(selectWhiteLabel);
  const isProduction = isProductionEnv();
  const intercomClient = window.Intercom;

  useEffect(() => {
    if (!isProduction || !intercomClient || !whiteLabel?.id) return;

    // Hide Intercom for specific white labels
    const shouldHideIntercom = HIDDEN_INTERCOM_WHITE_LABELS.includes(
      whiteLabel.id,
    );

    intercomClient("update", {
      hide_default_launcher: isMobileView || shouldHideIntercom,
    });
  }, [intercomClient, isMobileView, isProduction, whiteLabel.id]);
};
