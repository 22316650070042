import { FC, useEffect, useMemo, useState } from "react";
import isString from "lodash/isString";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StreamEvent } from "@langchain/langgraph-sdk/dist/types";
import {
  LangChainMessage,
  convertLangchainMessages,
} from "@assistant-ui/react-langgraph";
import {
  useExternalStoreRuntime,
  AssistantRuntimeProvider,
  useExternalMessageConverter,
} from "@assistant-ui/react";

import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { AGENT_THREAD_FLOW } from "src/constants";
import { streamMessage } from "src/store/threads/threadsApi";
import {
  createThread,
  insertThreadMessages,
  insertThreadConfiguration,
} from "src/store/actions";
import { selectUserId, selectCompanyId } from "src/store/selectors";

import "@assistant-ui/react/styles/index.css";
import "./agentStyles.scss";

// Inner imports
import { ChatThread } from "./components";
import { convertAssistantMessage } from "./utils";

type Props = {
  threadId: Nullable<Thread.Data["id"]>;
  agentFlow: Thread.AgentId;
  configuration?: Record<string, unknown>;
  selectThreadId?: (value: Thread.Data["id"]) => void;
};

const agentUrl = process.env.REACT_APP_LANGGRAPH_URL || "";

export const Agent: FC<Props> = ({
  agentFlow,
  configuration,
  selectThreadId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);
  const companyId = useSelector(selectCompanyId);

  // Chat state
  const [messages, setMessages] = useState<LangChainMessage[]>([]);
  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);
  const [chatLoadingStatus, setChatLoadingStatus] =
    useState<LoadingStatus>("idle");

  // Get agent configuration
  const { id: agentId, flowTypes } = useMemo(
    () => AGENT_THREAD_FLOW[agentFlow],
    [agentFlow],
  );

  // Reset messages on component mount or when key changes
  useEffect(() => {
    setMessages([]);
    setChatLoadingStatus("idle");
    setActiveThreadId(null);
  }, []);

  // Convert messages for the UI
  const threadMessages = useExternalMessageConverter<LangChainMessage>({
    messages,
    callback: convertLangchainMessages,
    isRunning: chatLoadingStatus === "loading",
  });

  // Handle saving messages from the stream
  const saveMessages = async (
    threadId: Thread.Data["id"],
    { event, data }: { event: StreamEvent; data: any },
  ) => {
    const streamMessages = data as LangChainMessage[];
    if (!streamMessages.length) return;

    switch (event) {
      case "metadata":
      case "messages/metadata": {
        setChatLoadingStatus("loading");
        break;
      }
      case "messages/partial": {
        setMessages((state) => {
          const messagesMap = new Map(state.map((msg) => [msg.id, msg]));
          for (const streamMessage of streamMessages) {
            messagesMap.set(streamMessage.id, {
              ...messagesMap.get(streamMessage.id),
              ...streamMessage,
            });
          }
          const finalMessages = [...messagesMap.values()];
          dispatch(insertThreadMessages({ threadId, messages: finalMessages }));
          return finalMessages;
        });
        break;
      }
      case "messages/complete": {
        setMessages((state) => {
          const messagesMap = new Map(state.map((msg) => [msg.id, msg]));
          for (const streamMessage of streamMessages) {
            messagesMap.set(streamMessage.id, {
              ...messagesMap.get(streamMessage.id),
              ...streamMessage,
            });
          }
          const finalMessages = [...messagesMap.values()];
          dispatch(insertThreadMessages({ threadId, messages: finalMessages }));
          return finalMessages;
        });

        const lastStreamMessage = streamMessages[streamMessages.length - 1]!;
        if (lastStreamMessage.type === "ai" && lastStreamMessage.content) {
          setChatLoadingStatus("succeeded");
        }
        break;
      }
    }
  };

  // Main runtime for the assistant
  const runtime = useExternalStoreRuntime({
    messages: threadMessages,
    isRunning: chatLoadingStatus === "loading",
    onCancel: async () => setChatLoadingStatus("succeeded"),
    setMessages: (messages) => messages.map(convertAssistantMessage),
    onNew: async (message): Promise<void> => {
      setChatLoadingStatus("loading");
      const convertedMessage = convertAssistantMessage(message);

      // Add message to UI
      setMessages((state) => [...state, convertedMessage]);

      // Get flow type from message content
      const flowType = flowTypes?.[convertedMessage?.content as string] ?? "";

      try {
        // Create a new thread if we don't have one
        let newThreadId = activeThreadId;
        if (!newThreadId) {
          const threadTitle = isString(convertedMessage.content)
            ? convertedMessage.content ||
              t("component.agent.label.untitled_thread")
            : t("component.agent.label.untitled_thread");

          const threadData = await dispatch(
            createThread({
              agentUrl,
              flowType,
              companyId,
              messages: [],
              updatedAt: "",
              createdAt: "",
              deletedAt: null,
              graphId: agentId,
              authorId: userId,
              title: threadTitle,
            }),
          ).unwrap();

          newThreadId = threadData.id;
          setActiveThreadId(newThreadId);

          // Save the thread ID to the parent component if needed
          if (selectThreadId && newThreadId) {
            selectThreadId(newThreadId);
          }
        }

        if (!newThreadId) return;

        // Save configuration
        dispatch(
          insertThreadConfiguration({ threadId: newThreadId, configuration }),
        );

        // Stream the message response
        await streamMessage({
          agentId,
          agentUrl,
          threadId: newThreadId,
          callback: saveMessages,
          messages: [convertedMessage],
          configuration: { ...configuration, flowType },
        });
      } catch (error) {
        console.error(error);
        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
        setChatLoadingStatus("failed");
      }
    },
  });

  return (
    <AssistantRuntimeProvider runtime={runtime}>
      <ChatThread
        messages={messages}
        agentFlow={agentFlow}
        configuration={configuration}
      />
    </AssistantRuntimeProvider>
  );
};
