import { FC, useMemo } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { LangChainMessage } from "@assistant-ui/react-langgraph";
import { ThreadWelcome, ThreadPrimitive } from "@assistant-ui/react";

import { getRandomArrayItems } from "src/utils";
import { AGENT_THREAD_FLOW } from "src/constants";
import { selectWhiteLabel } from "src/store/selectors";
import {
  UserMessage,
  ChatComposer,
  AssistantTools,
  AssistantMessage,
  ChatScrollToBottom,
} from "../../components";

// Inner imports
import { renderMessage } from "./utils";

type Props = {
  className?: string;
  agentFlow: Thread.AgentId;
  messages: LangChainMessage[];
  configuration?: Record<string, unknown>;
};

export const ChatThread: FC<Props> = ({
  messages,
  agentFlow,
  configuration,
}) => {
  const whiteLabel = useSelector(selectWhiteLabel);

  const {
    suggestions,
    welcomeMessage,
    inputPlaceholder,
    alignInputIfEmpty,
    welcomeSubMessages,
    suggestionCountToShow,
  } = useMemo(() => AGENT_THREAD_FLOW[agentFlow], [agentFlow]);

  const suggestedPrompts = useMemo(
    () =>
      getRandomArrayItems(suggestions, suggestionCountToShow).map(
        (suggestion) => {
          if (typeof suggestion === "string") {
            return { title: suggestion, prompt: suggestion };
          }
          return suggestion;
        },
      ),
    [suggestionCountToShow, suggestions],
  );

  const ThreadAlignedInput = alignInputIfEmpty ? (
    <>
      <ThreadPrimitive.If empty>
        <ChatComposer
          messages={messages}
          submitDisabled={false}
          placeholder={inputPlaceholder}
        />
      </ThreadPrimitive.If>
      <div className="aui-thread-welcome-suggestions">
        {suggestedPrompts.map(({ title, prompt }) => (
          <ThreadPrimitive.Suggestion
            autoSend
            key={prompt}
            prompt={prompt}
            className="aui-thread-welcome-suggestion"
          >
            {title}
          </ThreadPrimitive.Suggestion>
        ))}
      </div>
    </>
  ) : null;

  const ThreadFooter = alignInputIfEmpty ? (
    <div className="aui-thread-viewport-footer">
      <ChatScrollToBottom />
      <ThreadPrimitive.If empty={false}>
        <ChatComposer
          messages={messages}
          submitDisabled={false}
          placeholder={inputPlaceholder}
        />
      </ThreadPrimitive.If>
    </div>
  ) : (
    <div className="aui-thread-viewport-footer">
      <ChatScrollToBottom />
      <ThreadPrimitive.If empty>
        <div className="aui-thread-welcome-suggestions">
          {suggestedPrompts.map(({ title, prompt }) => (
            <ThreadPrimitive.Suggestion
              autoSend
              key={prompt}
              prompt={prompt}
              className="aui-thread-welcome-suggestion"
            >
              {title}
            </ThreadPrimitive.Suggestion>
          ))}
        </div>
      </ThreadPrimitive.If>
      <ChatComposer
        messages={messages}
        submitDisabled={false}
        placeholder={inputPlaceholder}
      />
    </div>
  );

  return (
    <ThreadPrimitive.Root
      className={cx("aui-root", "aui-thread-root", agentFlow)}
    >
      <ThreadPrimitive.Viewport className="aui-thread-viewport">
        <ThreadWelcome.Root className="aui-thread-welcome-root">
          <ThreadWelcome.Center className="aui-thread-welcome-center">
            <div className="aui-avatar-root">
              <img
                alt={whiteLabel.name}
                src={whiteLabel.logos.small}
                className="aui-avatar-image"
              />
            </div>
            <ThreadWelcome.Message
              className="aui-thread-welcome-message"
              message={renderMessage(welcomeMessage, configuration)}
            />
            <div className="aui-thread-welcome-submessages">
              {welcomeSubMessages?.map((message) => (
                <ThreadWelcome.Message
                  key={message}
                  className="aui-thread-welcome-submessage"
                  message={renderMessage(message, configuration)}
                />
              ))}
            </div>
            {ThreadAlignedInput}
          </ThreadWelcome.Center>
        </ThreadWelcome.Root>
        <AssistantTools configuration={configuration} />
        <ThreadPrimitive.Messages
          components={{ UserMessage, AssistantMessage }}
        />
        <div style={{ flexGrow: 1 }} />
        <ThreadPrimitive.If empty={false} running={false}>
          <div className="aui-thread-followup-suggestions" />
        </ThreadPrimitive.If>
        {ThreadFooter}
      </ThreadPrimitive.Viewport>
    </ThreadPrimitive.Root>
  );
};
