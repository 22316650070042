import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SelectCategorySection.module.scss";
import { useAppDispatch } from "src/store";
import { generateDocId } from "src/store/utils";
import { createSearch } from "src/store/actions";
import { showToastNotification } from "src/utils";
import { ArrowBoldLeft } from "src/assets/icons";
import { selectTrackersById } from "src/store/selectors";
import { useQueryParams, useUnmountEffect } from "src/hooks";
import { Button, Form, Preloader, Transition } from "src/components";
import {
  GREEN,
  TRACKER_KEYWORD_CATEGORY,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";
import { CreateTrackerStep } from "../../types";
import { getDefaultSuggestedSearch } from "../../utils";
import {
  useGetSuggestedTrackers,
  useGetSuggestedPerspectives,
} from "../../hooks";

// Inner imports
import { SuggestedCategories } from "./components";

type Props = {
  query: string;
  category: Tracker.Category | null;
  dashboard?: Dashboard.Data;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource | null;
  suggestedCategories: Tracker.Category[];
  stepBackHandler: (value: CreateTrackerStep) => void;
  updateSuggestedCategoriesHandler: (value: Tracker.Category[]) => void;
  submitHandler: (value: {
    category: Tracker.Category;
    selectedTrackers: Tracker.CreationData[];
    selectedSearches: Record<Tracker.Data["id"], Search.CreationData[]>;
    suggestedTrackers: Tracker.CreationData[];
    suggestedCategories: Tracker.Category[];
    suggestedPerspectives: Tracker.Perspective[];
  }) => void;
};

export const SelectCategorySection: FC<Props> = ({
  query,
  dashboard,
  locationId,
  languageId,
  submitHandler,
  stepBackHandler,
  suggestedCategories,
  category: defaultCategory,
  keywordsDataSource: defaultKeywordsDataSource,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { topics: trackerIdsString = "" } = useQueryParams();

  const usedTrackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackerIdsString.split(","),
    [trackerIdsString],
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, usedTrackerIds),
  );

  const [category, setCategory] = useState<Tracker.Category | null>(
    defaultCategory,
  );

  const [suggestedTrackersStatus, setSuggestedTrackersStatus] =
    useState<LoadingStatus>("idle");

  const [createTrackerStatus, setCreateTrackerStatus] =
    useState<LoadingStatus>("idle");

  const { getSuggestedTrackers, cancelGetSuggestedTrackers } =
    useGetSuggestedTrackers({
      updateStatusHandler: setSuggestedTrackersStatus,
      updateExcludedTrackersHandler: () => {},
    });

  const { getSuggestedPerspectives, cancelGetSuggestedPerspectives } =
    useGetSuggestedPerspectives({ updateStatusHandler: () => {} });

  const hasDashboard = useMemo<boolean>(() => Boolean(dashboard), [dashboard]);

  const keywordsDataSource = useMemo<Search.KeywordsDataSource>(
    () => defaultKeywordsDataSource || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
    [defaultKeywordsDataSource],
  );

  const isSuggestedTrackersLoading = useMemo<boolean>(
    () => suggestedTrackersStatus === "loading",
    [suggestedTrackersStatus],
  );

  const isCreateTrackerLoading = useMemo<boolean>(
    () => createTrackerStatus === "loading",
    [createTrackerStatus],
  );

  const isLoading = useMemo<boolean>(
    () => isSuggestedTrackersLoading || isCreateTrackerLoading,
    [isCreateTrackerLoading, isSuggestedTrackersLoading],
  );

  const isSubmitAvailable = useMemo<boolean>(() => Boolean(query), [query]);

  const isGoBackAvailable = useMemo<boolean>(() => !isLoading, [isLoading]);

  const isFooterShown = useMemo<boolean>(
    () => isSubmitAvailable,
    [isSubmitAvailable],
  );

  const existingTrackers = useMemo<Tracker.CreationData[]>(() => {
    const formattedTrackers = new Set<Tracker.CreationData>();

    for (const tracker of trackers) {
      formattedTrackers.add({
        id: tracker.id,
        name: tracker.name,
        category: tracker.category,
        locationId: "",
        languageId: "",
        description: tracker.description,
        keywordsDataSources: [],
      });
    }

    return Array.from(formattedTrackers);
  }, [trackers]);

  useUnmountEffect(() => {
    cancelGetSuggestedTrackers();
    cancelGetSuggestedPerspectives();
  });

  const onSubmit = (value: Tracker.Category | null = category): void => {
    if (!isSubmitAvailable || !value) return;

    if (value.category === TRACKER_KEYWORD_CATEGORY) {
      setCreateTrackerStatus("loading");

      const tracker: Tracker.CreationData = {
        id: generateDocId(),
        languageId,
        locationId,
        name: query,
        category: value.subject,
        description: value.description,
        keywordsDataSources: [keywordsDataSource],
      };

      const search = getDefaultSuggestedSearch({
        locationId,
        languageId,
        subject: query,
        keywordsDataSource,
        description: value.description,
        keywordsSelectionMethod: "exact-match",
      });

      dispatch(createSearch(search))
        .unwrap()
        .then((newSearch) => {
          setCreateTrackerStatus("succeeded");

          submitHandler({
            category: value,
            selectedTrackers: [tracker],
            selectedSearches: { [tracker.id]: [newSearch] },
            suggestedTrackers: [],
            suggestedCategories,
            suggestedPerspectives: [],
          });
        })
        .catch((error) => {
          console.error(error);

          showToastNotification({
            type: "error",
            text: t("common.error.server_error"),
          });

          setCreateTrackerStatus("failed");
        });
    } else {
      Promise.all([
        getSuggestedTrackers({
          languageId,
          locationId,
          category: value,
          keywordsDataSource,
          excludedTrackers: [...existingTrackers],
        }),
        getSuggestedPerspectives({
          languageId,
          locationId,
          category: value,
        }),
      ]).then(([suggestedTrackers, suggestedPerspectives]) =>
        submitHandler({
          category: value,
          suggestedTrackers,
          suggestedCategories,
          selectedTrackers: [],
          selectedSearches: {},
          suggestedPerspectives,
        }),
      );
    }
  };

  const onSelectCategory = (category: Tracker.Category): void => {
    if (isLoading) return;

    setCategory(category);

    onSubmit(category);
  };

  const onCancel = (): void => history.goBack();

  const stepBack = (): void => stepBackHandler("category");

  return (
    <Form
      onSubmit={() => onSubmit()}
      className={styles.wrapper}
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>
            <Button
              onClick={stepBack}
              disabled={!isGoBackAvailable}
              buttonSize="medium"
            >
              <ArrowBoldLeft />
            </Button>
            <div className={styles.subTitle}>
              <span className={styles.heading}>
                {t("page.create_tracker.select_category.heading")}
              </span>
              <span className={styles.subHeading}>
                {t("page.create_tracker.select_category.subheading")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <SuggestedCategories
            isLoading={isLoading}
            selectedCategory={category}
            suggestedCategories={suggestedCategories}
            selectCategoryHandler={onSelectCategory}
          />
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Transition
          in={isFooterShown}
          classNames={{
            exit: styles.slideOutBottom,
            enter: styles.slideInBottom,
          }}
        >
          <div className={styles.footer}>
            <div>
              <div className={styles.nextStep}>
                <Button
                  onClick={stepBack}
                  disabled={!isGoBackAvailable}
                  buttonSize="small"
                >
                  <ArrowBoldLeft />
                </Button>
                <span>
                  {t("page.create_tracker.select_category.label.next_step")}
                </span>
                <span>
                  {t(
                    "page.create_tracker.select_category.label.select_trackers",
                  )}
                </span>
                {isLoading && <Preloader type="beat" color={GREEN} />}
              </div>
              <div className={styles.submit}>
                <Transition
                  in={hasDashboard && !isLoading}
                  classNames={{ exit: styles.slideOutRight }}
                >
                  <Button
                    onClick={onCancel}
                    disabled={isLoading}
                    className={styles.cancelButton}
                    buttonSize="large"
                    buttonStyle="transparent"
                  >
                    <span>
                      {t(
                        "page.create_tracker.select_category.form.button.cancel",
                      )}
                    </span>
                  </Button>
                </Transition>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Form>
  );
};
