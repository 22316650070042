import { useMemo } from "react";

import { CHART_COLORS, GRAY } from "src/constants";

// Inner imports
import type { ChartLabel } from "../types";
import { CATEGORY_TREND_LINE } from "../constants";

type Props = {
  isTrendLineShown: boolean;
  allTrackerIds?: Tracker.Data["id"][];
  trackers: Pick<Tracker.Data, "id" | "name">[];
};

type ReturnProps = ChartLabel[];

export const useLineChartLabels = ({
  trackers,
  allTrackerIds,
  isTrendLineShown,
}: Props): ReturnProps => {
  return useMemo<ChartLabel[]>(() => {
    const mappedLabels = new Map<Tracker.Data["id"], ChartLabel>();

    if (allTrackerIds?.length) {
      for (const [index, id] of allTrackerIds.entries()) {
        const tracker = trackers.find((tracker) => tracker.id === id);

        if (!tracker) continue;

        mappedLabels.set(id, {
          value: id,
          label: tracker.name,
          priority: mappedLabels.size + 1,
          color: CHART_COLORS[index] || GRAY,
        });
      }
    } else {
      for (const [index, { id, name }] of trackers.entries()) {
        mappedLabels.set(id, {
          value: id,
          label: name,
          priority: mappedLabels.size + 1,
          color: CHART_COLORS[index] || GRAY,
        });
      }
    }

    const labels = Array.from(mappedLabels.values());

    if (isTrendLineShown) labels.push({ priority: 0, ...CATEGORY_TREND_LINE });

    return labels.sort(
      ({ priority: aPriority = -1 }, { priority: bPriority = -1 }) =>
        aPriority - bPriority,
    );
  }, [allTrackerIds, isTrendLineShown, trackers]);
};
