import { RawChartItem } from "src/components/charts/LineChart/types";

// Inner imports
import { SearchShareLineChartTool } from "./types";

export const formatSearchShareLineChartData = (
  data: SearchShareLineChartTool["data"],
): RawChartItem[] => {
  const mappedData = new Map<string, Record<Tracker.Data["id"], number>>();

  for (const { topicId: trackerId, lineChart } of data) {
    for (const { date, volume } of lineChart) {
      const data = mappedData.get(date);

      if (data) mappedData.set(date, { ...data, [trackerId]: volume });
      else mappedData.set(date, { [trackerId]: volume });
    }
  }

  const formattedData = new Set<RawChartItem>();

  for (const [date, values] of mappedData) {
    let totalValue = 0;

    for (const trackerId in values) {
      const value = values[trackerId];

      if (value) totalValue += value;
    }

    const formattedValues = new Map<Tracker.Data["id"], number>();

    for (const trackerId in values) {
      const value = values[trackerId];

      const formattedValue =
        value && totalValue ? (value / totalValue) * 100 : 0;

      formattedValues.set(trackerId, formattedValue);
    }

    formattedData.add({ date, values: Object.fromEntries(formattedValues) });
  }

  return [...formattedData];
};
