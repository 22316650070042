import { useCallback } from "react";
import { useAssistantToolUI } from "@assistant-ui/react";

// Inner imports
import { AssistantSearchSharePieChartTool } from "../components";

type Props = {
  configuration?: Record<string, unknown>;
};

export const useAssistantSearchSharePieChartTool = ({}: Props) =>
  useAssistantToolUI({
    toolName: "generate_share_of_search_pie_chart_tool",
    render: useCallback(AssistantSearchSharePieChartTool, []),
  });
