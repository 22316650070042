import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ROUTE_PATH_REPLACEMENT: Record<string, string> = {
  tracker: "topic",
  "market-brief": "dashboard",
};

const ROUTE_PATH_DIVIDER: string = "/";

export const usePathReplace = (): void => {
  const history = useHistory();

  useEffect(() => {
    const { pathname, state, search, hash } = history.location;

    const splitPathname = pathname.split(ROUTE_PATH_DIVIDER);

    for (const [index, path] of splitPathname.entries()) {
      const pathReplace = ROUTE_PATH_REPLACEMENT[path];

      if (!pathReplace) continue;

      splitPathname.splice(index, 1, pathReplace);
    }

    const formattedPathname = splitPathname.join(ROUTE_PATH_DIVIDER);

    history.replace(`${formattedPathname}${search}${hash}`, state);
  }, [history]);
};
