import { RawChartItem } from "src/components/charts/LineChart/types";

// Inner imports
import { VolumeShareLineChartTool } from "./types";

export const formatVolumeShareLineChartData = (
  data: VolumeShareLineChartTool["data"],
): RawChartItem[] => {
  const mappedData = new Map<string, Record<Tracker.Data["id"], number>>();

  for (const { topicId: trackerId, lineChart } of data) {
    for (const { date, volume } of lineChart) {
      const data = mappedData.get(date);

      if (data) mappedData.set(date, { ...data, [trackerId]: volume });
      else mappedData.set(date, { [trackerId]: volume });
    }
  }

  const formattedData = new Set<RawChartItem>();

  for (const [date, values] of mappedData) formattedData.add({ date, values });

  return [...formattedData];
};
