// Inner imports
import { SearchSharePieChartTool } from "./types";

export const formatSearchSharePieChartData = (
  data: SearchSharePieChartTool["data"],
  startDate: string,
  endDate: string,
): Widgets.PieChart.Item => {
  const mappedData = new Set<Widgets.PieChart.Value>();

  for (const { topicId, pieChart } of data)
    mappedData.add({
      trackerId: topicId,
      value: pieChart.percentage,
      additionalValue: pieChart.volume,
    });

  return { endDate, startDate, values: [...mappedData] };
};
