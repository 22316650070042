// Inner imports
import { VolumeSharePieChartTool } from "./types";

export const formatVolumeSharePieChartData = (
  data: VolumeSharePieChartTool["data"],
  startDate: string,
  endDate: string,
): Widgets.PieChart.Item => {
  const mappedData = new Set<Widgets.PieChart.Value>();

  for (const { topicId, pieChart } of data)
    mappedData.add({ value: pieChart, trackerId: topicId });

  return { endDate, startDate, values: [...mappedData] };
};
