import { FC, useCallback, useContext, useMemo } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./DashboardActionButtons.module.scss";
import Context from "src/context";
import { useModal, useTrackerBlocker } from "src/hooks";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { updateDashboard } from "src/store/actions";
import { MenuOption } from "src/components/MenuDropdown/types";
import { showToastNotification, triggerGtmEvent } from "src/utils";
import { Button, MenuDropdown, Translation } from "src/components";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import { Dashboard } from "src/assets/icons";
import {
  CopyDashboardModal,
  ShareDashboardModal,
  AddTrackerToCompare,
  TrackerKeywordsDataSourcesIcon,
} from "src/features";
import {
  selectUser,
  selectDashboardById,
  selectDashboardsLimit,
  selectUserPermissions,
  selectHasDashboardAccess,
  selectIsCompanyDashboard,
  selectTrackersCollectionTrackers,
} from "src/store/selectors";
import { useDashboardSettingsOptions } from "../../../../hooks";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  setExpandedWidgetIdHandler: (value: Widget.IdType | null) => void;
};

export const DashboardActionButtons: FC<Props> = ({
  isReadOnly,
  trackersCollectionId,
  dashboardDateRangeId,
  setExpandedWidgetIdHandler,
}) => {
  const { t } = useTranslation();

  const { setModal, closeModal } = useModal();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { isUserAuthenticated } = useContext(Context);

  const { setSidebarContent, closeSidebar } = useContext(DashboardPageContext);

  const {
    hasTrackerAccess,
    isTrackersLimitExceeded,
    showTrackerLimitNotification,
    showSubscriptionPlanLimitNotification,
  } = useTrackerBlocker();

  const user = useSelector(selectUser);

  const { hasDashboardAccess } = useSelector(selectHasDashboardAccess);

  const { isUserCompanyOwnerOrAdmin } = useSelector(selectUserPermissions);

  const { dashboardsLimit, isDashboardsLimitExceeded } = useSelector(
    selectDashboardsLimit,
  );

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionTrackers(state, trackersCollectionId),
  );

  const dashboardSettingsOptions = useDashboardSettingsOptions({
    closeSidebar,
    setSidebarContent,
    trackersCollectionId,
    dashboardDateRangeId,
    setExpandedWidgetId: setExpandedWidgetIdHandler,
  });

  const firstTracker = useMemo<Tracker.Data | undefined>(
    () => trackers[0],
    [trackers],
  );

  const isUserDashboardOwner = useMemo<boolean>(
    () => user.id === dashboard?.authorId,
    [dashboard?.authorId, user.id],
  );

  const isAddTrackerButtonShown = useMemo<boolean>(() => {
    if (!isCompanyDashboard || isReadOnly || dashboard?.layoutView !== "full")
      return false;

    if (!dashboard?.isLocked) return true;

    return isUserDashboardOwner || isUserCompanyOwnerOrAdmin;
  }, [
    isReadOnly,
    isCompanyDashboard,
    dashboard?.isLocked,
    isUserDashboardOwner,
    dashboard?.layoutView,
    isUserCompanyOwnerOrAdmin,
  ]);

  const isShareDashboardShown = useMemo<boolean>(
    () => isCompanyDashboard && !isReadOnly,
    [isCompanyDashboard, isReadOnly],
  );

  const isFullLayoutButtonShown = useMemo<boolean>(
    () =>
      isCompanyDashboard && !isReadOnly && dashboard?.layoutView === "light",
    [isCompanyDashboard, isReadOnly, dashboard?.layoutView],
  );

  const isCopyDashboardShown = useMemo<boolean>(
    () =>
      isUserAuthenticated &&
      !isCompanyDashboard &&
      isReadOnly &&
      hasDashboardAccess,
    [isUserAuthenticated, isCompanyDashboard, isReadOnly, hasDashboardAccess],
  );

  const isEditTrackerShown = useMemo<boolean>(() => {
    if (!isCompanyDashboard || isReadOnly) return false;

    if (!dashboard?.isLocked) return true;

    return isUserDashboardOwner || isUserCompanyOwnerOrAdmin;
  }, [
    isReadOnly,
    isCompanyDashboard,
    dashboard?.isLocked,
    isUserDashboardOwner,
    isUserCompanyOwnerOrAdmin,
  ]);

  const isDashboardMenuShown = useMemo<boolean>(() => {
    if (!isCompanyDashboard || isReadOnly) return false;

    if (!dashboard?.isLocked) return true;

    return isUserDashboardOwner || isUserCompanyOwnerOrAdmin;
  }, [
    isReadOnly,
    isCompanyDashboard,
    dashboard?.isLocked,
    isUserDashboardOwner,
    isUserCompanyOwnerOrAdmin,
  ]);

  const onEditTrackerClick = useCallback(
    ({ id }: Tracker.Data): void => {
      if (!hasTrackerAccess) return showSubscriptionPlanLimitNotification();

      return history.push(`${ROUTES.trackersPage}/edit/${id}`, {
        redirectUrl: history.location.pathname,
      });
    },
    [history, hasTrackerAccess, showSubscriptionPlanLimitNotification],
  );

  const editTrackerOptions = useMemo<MenuOption[]>(
    () =>
      trackers.map((tracker) => ({
        label: tracker.name,
        onClick: () => onEditTrackerClick(tracker),
        icon: <TrackerKeywordsDataSourcesIcon trackerId={tracker.id} />,
      })),
    [onEditTrackerClick, trackers],
  );

  const onAddTrackerSubmit = useCallback((): void => {
    closeSidebar();

    closeModal("add-tracker");
  }, [closeModal, closeSidebar]);

  const onAddToCompareButtonClick = (): void => {
    if (!isCompanyDashboard) return;

    if (!hasTrackerAccess) return showSubscriptionPlanLimitNotification();

    setSidebarContent(
      <AddTrackerToCompare
        submitHandler={onAddTrackerSubmit}
        trackersCollectionId={trackersCollectionId}
      />,
    );
  };

  const onShareDashboardClick = (): void =>
    setModal(
      "share-dashboard",
      <ShareDashboardModal
        dashboardId={trackersCollectionId}
        dashboardDateRangeId={dashboardDateRangeId}
      />,
    );

  const onChangeFullLayoutClick = async (): Promise<void> => {
    if (dashboard?.layoutView === "full") return;

    try {
      await dispatch(
        updateDashboard({
          id: trackersCollectionId,
          changes: { layoutView: "full" },
        }),
      );

      triggerGtmEvent("DashboardExpand", { dashboardId: trackersCollectionId });
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const onDuplicateDashboardClick = (): void => {
    if (isCompanyDashboard) return;

    if (isDashboardsLimitExceeded)
      return showToastNotification({
        id: "dashboard-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="dashboard.status.warning.dashboard_limit"
            values={{ count: dashboardsLimit }}
          />
        ),
      });

    if (isTrackersLimitExceeded) return showTrackerLimitNotification();

    setModal(
      "copy-dashboard",
      <CopyDashboardModal dashboardId={trackersCollectionId} />,
    );
  };

  return (
    <div className={styles.wrapper}>
      {isAddTrackerButtonShown && (
        <Button
          buttonStyle="transparent"
          onClick={onAddToCompareButtonClick}
          className={cx(styles.button, styles.buttonDesktopWrapper)}
        >
          <span>{t("page.dashboard.button.compare_tracker")}</span>
        </Button>
      )}
      {isShareDashboardShown && (
        <Button
          buttonStyle="transparent"
          onClick={onShareDashboardClick}
          className={cx(styles.button, styles.buttonDesktopWrapper)}
        >
          <span>{t("page.dashboard.button.share_dashboard")}</span>
        </Button>
      )}
      {isEditTrackerShown &&
        (trackers.length === 1 && firstTracker ? (
          <Button
            buttonStyle="transparent"
            onClick={() => onEditTrackerClick(firstTracker)}
            className={cx(styles.button, styles.buttonDesktopWrapper)}
          >
            <span>{t("page.dashboard.button.edit_tracker")}</span>
          </Button>
        ) : (
          <MenuDropdown
            buttonContent={
              <>
                <span>{t("page.dashboard.button.edit_tracker")}</span>
              </>
            }
            options={editTrackerOptions}
            dropdownDirection={{ y: "down", x: "left" }}
            buttonClassName={cx(styles.button, styles.buttonDesktopWrapper)}
          />
        ))}
      {isFullLayoutButtonShown && (
        <Button className={styles.button} onClick={onChangeFullLayoutClick}>
          <Dashboard />
          <span>{t("page.dashboard.button.expand_layout")}</span>
        </Button>
      )}
      {isCopyDashboardShown && (
        <Button
          className={styles.buttonFilled}
          onClick={onDuplicateDashboardClick}
        >
          <span>{t("page.dashboard.button.copy_dashboard")}</span>
        </Button>
      )}
      {isDashboardMenuShown && (
        <MenuDropdown
          hasSingleOptionAsSubmenu
          options={dashboardSettingsOptions}
          dropdownDirection={{ x: "left", y: "down" }}
          className={styles.dashboardMenuWrapper}
        />
      )}
    </div>
  );
};
