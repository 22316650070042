import { useCallback } from "react";
import { useAssistantToolUI } from "@assistant-ui/react";

// Inner imports
import { AssistantSearchShareLineChartTool } from "../components";

type Props = {
  configuration?: Record<string, unknown>;
};

export const useAssistantSearchShareLineChartTool = ({
  configuration,
}: Props) =>
  useAssistantToolUI({
    toolName: "generate_share_of_search_line_chart_tool",
    render: useCallback(
      (input) => (
        <AssistantSearchShareLineChartTool
          {...input}
          dashboardId={configuration?.dashboardId}
        />
      ),
      [configuration?.dashboardId],
    ),
  });
