import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  ThreadPrimitive,
  MessagePrimitive,
  ActionBarPrimitive,
} from "@assistant-ui/react";

import { Tooltip } from "src/components";
import { CheckMark, CopyOutline } from "src/assets/icons";

// Inner imports
import { AssistantMessageEmpty, AssistantMessageMarkdown } from "../";

export const AssistantMessage: FC = () => {
  const { t } = useTranslation();

  return (
    <MessagePrimitive.Root className="aui-assistant-message-root">
      <div className="aui-assistant-message-content">
        <MessagePrimitive.Content
          components={{
            Empty: AssistantMessageEmpty,
            Text: AssistantMessageMarkdown,
          }}
        />
        <ThreadPrimitive.If running={false}>
          <ActionBarPrimitive.Root className="aui-assistant-action-bar-root">
            <ActionBarPrimitive.Copy>
              <MessagePrimitive.If copied={false}>
                <Tooltip content={t("component.agent.label.copy_response")}>
                  <CopyOutline />
                </Tooltip>
              </MessagePrimitive.If>
              <MessagePrimitive.If copied>
                <Tooltip content={t("component.agent.label.copied_response")}>
                  <CheckMark />
                </Tooltip>
              </MessagePrimitive.If>
            </ActionBarPrimitive.Copy>
          </ActionBarPrimitive.Root>
        </ThreadPrimitive.If>
      </div>
    </MessagePrimitive.Root>
  );
};
