import React, { FC, PropsWithChildren } from "react";
import styles from "./CreateTrackersContainer.module.scss";

type Props = PropsWithChildren & {
  className?: string;
};

export const CreateTrackersContainer: FC<Props> = ({ children, className }) => {
  return (
    <div className={`${styles.container} ${className || ""}`}>{children}</div>
  );
};
