import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LangChainMessage } from "@assistant-ui/react-langgraph";
import { ComposerPrimitive, ThreadPrimitive } from "@assistant-ui/react";

import { Send } from "src/assets/icons";

type Props = {
  messages: LangChainMessage[];
  submitDisabled: boolean;
  placeholder?: string;
};

export const ChatComposer: FC<Props> = ({
  submitDisabled,
  placeholder = "",
}) => {
  const { t } = useTranslation();

  return (
    <ComposerPrimitive.Root className="aui-composer-root">
      <ComposerPrimitive.Input
        rows={1}
        autoFocus
        className="aui-composer-input"
        placeholder={t(placeholder)}
      />
      <ThreadPrimitive.If running={false} disabled={submitDisabled}>
        <ComposerPrimitive.Send className="aui-button aui-button-icon aui-composer-send">
          <Send />
        </ComposerPrimitive.Send>
      </ThreadPrimitive.If>
      {/*<ThreadPrimitive.If running>*/}
      {/*  <ComposerPrimitive.Cancel className="aui-button aui-button-icon aui-composer-cancel">*/}
      {/*    <StopCircleOutline />*/}
      {/*  </ComposerPrimitive.Cancel>*/}
      {/*</ThreadPrimitive.If>*/}
    </ComposerPrimitive.Root>
  );
};
