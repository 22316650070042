import { FC, useCallback, useMemo } from "react";
import isString from "lodash/isString";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./CustomTooltip.module.scss";
import { selectTrackersEntities } from "src/store/selectors";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";

// Inner imports
import { formatPayload, getCleanDataKey } from "./utils";
import { CustomTooltipProps, PreparedPayload } from "./types";

export const CustomTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  trackers,
  formatDateHandler,
  valueFormatter,
  additionalValueFormatter,
}) => {
  const { t } = useTranslation();

  const trackersEntities = useSelector(selectTrackersEntities);

  const formattedPayload = useMemo<PreparedPayload>(
    () => formatPayload(payload),
    [payload],
  );

  const hasTooltipValues = useMemo<boolean>(
    () => Boolean(formattedPayload.length),
    [formattedPayload],
  );

  const getFormattedValue = useCallback(
    ({ value }: PreparedPayload[number]): string | number => {
      if (!value) return 0;

      if (valueFormatter) return valueFormatter(value);

      return value;
    },
    [valueFormatter],
  );

  const renderLabel = useCallback(
    ({ dataKey }: PreparedPayload[number]): JSX.Element | null => {
      if (!dataKey || !isString(dataKey)) return null;

      const cleanDataKey = getCleanDataKey(dataKey);

      if (!cleanDataKey) return null;

      const name =
        trackers.find(({ name }) => name === cleanDataKey)?.name ||
        trackersEntities[cleanDataKey]?.name;

      if (!name) return null;

      return (
        <span className={styles.itemLabel}>
          <span>{name}</span>
          <TrackerKeywordsDataSourcesIcon trackerId={cleanDataKey} />
        </span>
      );
    },
    [trackers, trackersEntities],
  );

  const getFormattedAdditionalValue = useCallback(
    ({ additionalValue }: PreparedPayload[number]): string | number => {
      if (!additionalValue) return "";

      if (additionalValueFormatter)
        return `(${additionalValueFormatter(additionalValue)})`;

      return `(${additionalValue})`;
    },
    [additionalValueFormatter],
  );

  if (!active) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{formatDateHandler?.(label)}</div>
      {hasTooltipValues && (
        <div className={styles.items}>
          {formattedPayload.map((item, index) => (
            <div className={styles.item} key={`${item.dataKey}_${index}`}>
              <div className={styles.itemTitle}>
                {Boolean(item.color) && <TrackerChartIcon color={item.color} />}
                {renderLabel(item)}
              </div>
              <span className={styles.itemValue}>
                {t("chart.line.tooltip.tracker_value", {
                  unit: "",
                  value: getFormattedValue(item),
                  additionalValue: getFormattedAdditionalValue(item),
                })}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
